import React from "react";
import './../css/CambioClave.css';
import Mensaje from "./Mensaje";
import MiBoton from "./MiBoton";
class CambioClave extends React.Component {
    constructor(props){
        super(props);
        this.state= {
            clave: "",
            repetir: "",
            clave_mensaje: "Digite la clave",
            clave_fuerza: 0,
            mensaje: "",
            mensaje_proceso: null
        }
        this.evtChange = this.evtChange.bind(this);
        this.puedeCambiar = this.puedeCambiar.bind(this);
        this.cambia = this.cambia.bind(this);
        this.password_revisa = this.password_revisa.bind(this);
        this.evtCerrar = this.evtCerrar.bind(this);
    }

    password_revisa(pwd) {
        var mensaje = "";
        var fuerza = 0;
        var strongRegex = new RegExp("^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
        var mediumRegex = new RegExp("^(?=.{10,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
        var enoughRegex = new RegExp("(?=.{8,}).*", "g");
        if (pwd.length == 0) {
            mensaje = 'Digite la clave';
            fuerza = 0;
        } else if (false == enoughRegex.test(pwd)) {
            mensaje = 'Muy corta';
            fuerza = 0;
        } else if (strongRegex.test(pwd)) {
            mensaje = 'Fuerte!';
            fuerza = 2;
        } else if (mediumRegex.test(pwd)) {
            mensaje = 'Medio';
            fuerza = 1;
        } else {
            mensaje = 'Débil!';
            fuerza = 0;
        }
        this.setState({
            clave_mensaje: mensaje,
            clave_fuerza: fuerza
        })
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        })
        if (nombre=="clave"){
            this.password_revisa(target.value);
        }
        
    }

    puedeCambiar(){
        var puede = true;
        var mensaje = "";
        var clave = this.state.clave;
        var repetir = this.state.repetir;
        this.password_revisa(clave);
        if (clave.length==0){
            puede = false;
            mensaje = "Debe digitar la nueva clave";
        }
        if (this.state.clave_fuerza==0 && puede){
            puede = false;
            mensaje = "Clave muy débil";
        }
        if (this.state.repetir.length==0 && puede){
            puede = false;
            mensaje = "Debe repetir la clave por seguridad";
        }
        if (this.state.repetir!=this.state.clave && puede){
            puede = false;
            mensaje = "Claves no coinciden";
        }
        if (this.state.fuerza==0){
            puede = false;
            mensaje = "Clave muy débil";
        }
        this.setState({
            mensaje: mensaje
        })
        return puede;
    }

    async cambia(){
        if (this.puedeCambiar()){
            this.setState({
                procesando: true
            })
            var cliente = parseInt(this.props.cliente);
            var clave = this.state.clave;
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var miData = new URLSearchParams();
            miData.append("cliente", cliente);
            miData.append("clave", clave);

            var requestOptions = {
                method: 'POST',
                body: miData,
                Headers: myHeaders,
                redirect: 'follow'
            };
            let url = "https://api.cooeprouasd.net/api/login/cambio";
            try {
                const response = await fetch(url, requestOptions);
                if (response.ok){
                    const data = await response.json();
                    if (data.respuesta=="S"){
                        this.setState({
                            mensaje: "Su clave ha sido cambiada exitosamente",
                            mensaje_proceso: this.props.cierre
                        })
                    } else {
                        this.setState({
                            mensaje: data.mensaje
                        })
                    }
                } else {
                    this.setState({
                        mensaje: "Hubo un error de conexion. Por favor intente más tarde"
                    })
                    
                }
          } catch(error){
            this.setState({
                mensaje: "Hubo un error de conexion. Por favor intente mas tarde"
            })
          }
          this.setState({
              procesando: false
          })
        }
    }

    evtCerrar(){
        this.setState({
            mensaje: ""
        });
        if (this.state.mensaje_proceso!=null){
            this.state.mensaje_proceso();
        }
    }

    render(){
        console.log("estoy aqui...")
        const obj_msg = (this.state.mensaje.length>0?<Mensaje texto={this.state.mensaje} evtCerrar={this.evtCerrar}/>: null);
        const clase_fuerza = (this.state.clave_fuerza==0?"msg_fuerza":"msg_fuerza verde");
        return(
        <div className="Fondo-Cambio">
            <div className="Div-Cambio">
                <div className="Mens-Cambio">
                    {this.props.mensaje}
                </div>
                <div className="misRowsCC">
                    <div><label className='col-form-label misEtiquetasCC'>Nueva Contraseña:</label></div>
                    <div className="misRowsCC2">
                        <div><input type="password" className="form-control form-control-sm" autocomplete="off" placeholder="Contraseña" name="clave" value={this.state.clave} onChange={this.evtChange} style={{marginTop: '4px', maxWidth: '200px'}} /></div>
                        <div className={clase_fuerza}>{"(" + this.state.clave_mensaje + ")"}</div>
                    </div>
                </div>
                <div className="misRowsCC" style={{marginBottom: '20px'}}>
                    <div><label className='col-form-label misEtiquetasCC'>Repetir:</label></div>
                    <div className="misRows" style={{gridTemplateColumns: '200px 1fr'}}>
                        <div><input type="password" className="form-control form-control-sm" autocomplete="off" placeholder="Repetir" name="repetir" value={this.state.repetir} onChange={this.evtChange} style={{marginTop: '4px', maxWidth: '200px'}} /></div>
                        <div></div>
                    </div>
                </div>
                <div className="misRows" style={{gridTemplateColumns: '1fr 150px 1fr'}}>
                    <div></div>
                    <div>
                        <MiBoton enEspera={this.state.procesando} evtClick={this.cambia} miNombre="btCambiar" texto="Cambiar" />
                    </div>
                    <div></div>
                </div>
            </div>
            {obj_msg}
        </div>
        )
    }

}

export default CambioClave;