import React from "react";
import './../css/MiInfoValor.css';

class MiInfoValor extends React.Component {
    constructor(props){
        super(props);

    }

    render(){

        return(
            <div className="Div-MiInfoValor">
                <div className="MiInfoValor-Label">{this.props.label}</div>
                {this.props.valor}
            </div>
        )
    }
}

export default MiInfoValor;