import react, { useState, useEffect } from "react";
import "../css/ReelView.css";
import { Fab } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useRef } from "react";

const Player = ({ video }) => {

  useEffect(()=>{
    console.log("nuevo video...", video)
  }, [video])

  return <video key={video} controls={true} autoPlay>
    <source src={video} 
    type="video/mp4"/>
    
  </video>
}

export default function ReelView(props) {
  const [casaComercial, setCasaComercial] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [actual, setActual] = useState(0);
  const [anterior, setAnterior] = useState("");
  const [siguiente, setSiguiente] = useState("");
  const videoRef = useRef(null)

  useEffect(() => {
    actInfo();
  }, []);
  useEffect(() => {
    console.log("tokebn", props.token)
    actInfo()
    /*setTimeout(() => {
      let video = videoRef.current;
      //video.play()
      if (video){
        console.log("el video existe")
        var playPromise = video.play();
        console.log("la promesa", playPromise)
        if (playPromise !== undefined) {

          playPromise.then(_ => {
            console.log("funciono!")
            // Automatic playback started!
            // Show playing UI.
            video.pause();
          })
          .catch(error => {
            console.log("Error: ", error)
            // Auto-play was prevented
            // Show paused UI.
          });
        }
      }
      

    }, 3000)*/
  }, [props.token])

  const actInfo = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var miData = new URLSearchParams();
    miData.append("token", props.token);

    var requestOptions = {
      method: "POST",
      body: miData,
      Headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/videos/info";
    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const data = await response.json();
        if (data.respuesta == "S") {
          setCasaComercial(data.casacomercial);
          setActual(data.actual);
          setCantidad(data.cantidad);
          setAnterior(data.anterior);
          setSiguiente(data.siguiente);
        }
      } else {
      }
    } catch (error) { }
  };

  const handleBack = () => {
    if (anterior.length > 0) {
      props.cambio(anterior)
    }
  }

  const handleNext = () => {
    if (siguiente.length > 0) {
      props.cambio(siguiente)
    }
  }

  return (
    <div className="view-cuerpo">
      <div
        className="view-close"
        onClick={() => {
          props.close();
        }}
      >
        X
      </div>
      <div className="view-info">
        <div className="view-info-a">{casaComercial}</div>
        <div className="view-info-b">
          {"Anuncio " + actual + " de " + cantidad}
        </div>
      </div>
      <div className="view-div">
        <div className="btNext">
          <Fab color="primary" aria-label="add" onClick={handleNext}>
            <NavigateNextIcon />
          </Fab>
        </div>
        <div className="btPrev">
          <Fab color="primary" aria-label="add" onClick={handleBack}>
            <NavigateBeforeIcon />
          </Fab>
        </div>

        <Player video={"https://api.cooeprouasd.net/api/videos/vervideo?token=" +
          props.token} />

        {/* <video controls={false} autoPlay ref={videoRef}>
          <source
            src={
              "https://api.cooeprouasd.net/api/videos/vervideo?token=" +
              props.token
            }
            type="video/mp4"
          />
          Your browser does not support videos.
        </video> */}
      </div>
    </div>
  );
}
