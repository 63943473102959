import React from "react";
import "./../css/Stream.css";

class Stream extends React.Component {
    constructor(props){
        super(props);
    }


    render(){

        return(
            <div className="Div-Stream">
                <iframe
                    src={"https://www.facebook.com/plugins/video.php?href=" + this.props.link_stream}
                    frameborder="0"
                    scrolling="no"
                    allowFullScreen="true"
                    height="100%"
                    width="100%">
                </iframe>
            </div>
        )
    }

}

export default Stream;