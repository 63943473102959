import React from 'react';
import { Line } from 'react-chartjs-2';
import "./../css/ChartLine.css";

class LineChart extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            labels: "",
            datos: ""
        }
    }

    async actDatos(){
      let tk = "";
      try{
          tk = localStorage.getItem("tk");
      } catch(error){

      }
      var myHeaders = new Headers();
      myHeaders.append("tk", tk);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      
        var miData = new URLSearchParams();
        miData.append("tipo", 1);
        miData.append("cliente", this.props.cliente);

        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/Resumen/chart", requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data.respuesta=="S"){
                    this.setState({
                        labels: data.labels,
                        datos: data.data
                    });
                } else {
                    //alert("mensaje: " + data.mensaje)
                    
                }
            } else {
                //alert("Error de conexión...");
            }
          } catch(error){
            //alert("Error: " + error);
          }
    }

    componentDidMount(){
        this.actDatos();
    }

    render() {
        let a_labels = this.state.labels.split(";");
        let a_data = this.state.datos.split(";");
        const data = {
            labels: a_labels,
            datasets: [
              {
                label: 'Mis Ahorros',
                data: a_data,
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(16,138,51)',
              },
            ],
          };
          
          const options = {
            scales: {
              
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false,
                    min: 0
                  },
                  gridLines: {
                      display: false
                  }
                },
              ]
            },
            legend: {
                display: false
            }
          };
        return(
            <div id="cuerpo-chart">
                <Line data={data} options={options} />
            </div>
        )
    }
}

export default LineChart;