import React from "react";
import './../css/AhorrosModifica.css';
import RowTabla from "./RowTabla";
import PideValor from './PideValor.jsx';
import Confirmacion from './Confirmacion.jsx';
import Wait from './Wait.jsx';
import Mensaje from './Mensaje.jsx';
import Pregunta from "./Pregunta";


Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

class AhorrosModifica extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            datos:[],
            mensaje: "",
            mensaje_clase: "Error",
            mensaje_proceso: null,
            pide_valor: false,
            campo_valor: 0,
            monto_solicitado: 0.00,
            valor_minimo: 1,
            valor_maximo: 100000,
            montoInicial: "0.00",
            pide_confirmacion: false,
            pide_confirmacion2: false,
            procesando: true,
            proceso_mensaje: null,
            pregunta_seguridad: true
        }
        this.traeDatos = this.traeDatos.bind(this)
        this.evtClick = this.evtClick.bind(this);
        this.cierraVentanaValor = this.cierraVentanaValor.bind(this);
        this.valorTomado = this.valorTomado.bind(this);
        this.confirmaNo = this.confirmaNo.bind(this)
        this.confirmaOk = this.confirmaOk.bind(this)
        this.confirmaNo2 = this.confirmaNo2.bind(this)
        this.confirmaOk2 = this.confirmaOk2.bind(this)
        this.cerrarMensaje = this.cerrarMensaje.bind(this)
        this.cerrarProceso = this.cerrarProceso.bind(this)
        this.respuesta_correcta = this.respuesta_correcta.bind(this);
        this.eliminar = this.eliminar.bind(this);
        this.solicitar = this.solicitar.bind(this)

        this.props.titulo("Modificación de Ahorros");
        this.traeDatos();
    }

    evtClick(nombre){
        let id = nombre.substr(3, nombre.length-3);
        var a;
        let datos = this.state.datos;
        let inicial = "0.00";
        for(a=0;a<datos.length;a++){
            if (datos[a].id==id){
                inicial = datos[a].balance;
                break;
            }
        }
        if (nombre.substr(0,3)=="mod"){
            let minimo = 1;
            if (id ==1){minimo = 200}
            if (id ==2){minimo = 500}
            if (id ==3){minimo = 500}
            if (id ==4){minimo = 300}
            this.setState({
                pide_valor: true,
                campo_valor: id,
                valor_minimo: minimo,
                montoInicial: inicial
            })
        }
        else {
            this.setState({
                pide_confirmacion2: true,
                campo_valor: id
            })
        }
        
    }

    async traeDatos(){
        this.setState({
            procesando: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        //miData.append("cliente", 8760)

        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/ahorros/listamodificar";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data[0].respuesta=="S"){
                    var a;
                    var resp_datos = []
                    for(a=0;a<data.length; a++){
                        let item = {};
                        item.id = data[a].codigo;
                        item.columnas = [data[a].nombre, parseFloat(data[a].balance).format(2,3,',','.'), (parseInt(data[a].modificar)!=0?"[boton]:mod,Modificar":""), (parseInt(data[a].eliminar)!=0?"[boton]:eli,Eliminar":"")]
                        item.balance = data[a].balance
                        resp_datos.push(item);
                    }
                    
                    this.setState({
                        datos: resp_datos
                    });
                } else {
                    this.setState({
                        mensaje: data[0].mensaje,
                        mensaje_clase: "Error"
                    })
                    
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión",
                    mensaje_clase: "Error"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error: " + error,
                mensaje_clase: "Error"
            })
          }
          this.setState({
            procesando: false
        })
    }


    async solicitar(){
        this.setState({
            procesando: true
        })
        let tipo = this.state.campo_valor;
        let monto = this.state.monto_solicitado;
        let tk = "";
        try{
            tk = localStorage.getItem("tk");
        } catch(error){

        }
        var myHeaders = new Headers();
        myHeaders.append("tk", tk);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        //miData.append("cliente", 8760)
        miData.append("tipo", tipo);
        miData.append("monto", monto);

        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/ahorros/modificar";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                
                if (data.respuesta=="S"){
                    this.setState({
                        mensaje: "Su cuota de ahorro ha sido modificada exitosamente!",
                        mensaje_clase: "Info",
                        mensaje_proceso: this.cerrarProceso
                    })
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        mensaje_clase: "Error"
                    })
                    
                }
            } else {
                this.setState({
                    mensaje: "Error de conexión",
                    mensaje_clase: "Error"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error: " + error,
                mensaje_clase: "Error"
            })
          }
          this.setState({
            procesando: false
        })
    }


    async eliminar(){
        this.setState({
            procesando: true
        })
        let tipo = this.state.campo_valor;
        let monto = this.state.monto_solicitado;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        //miData.append("cliente", 8760)
        miData.append("tipo", tipo);
        miData.append("monto", monto);

        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/ahorros/eliminar";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data.respuesta=="S"){
                    this.setState({
                        mensaje: "Su cuota de ahorro ha sido eliminada exitosamente!",
                        mensaje_clase: "Info",
                        mensaje_proceso: this.cerrarProceso
                    })
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        mensaje_clase: "Error"
                    })
                    
                }
            } else {
                this.setState({
                    mensaje: "Error de conexión",
                    mensaje_clase: "Error"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error: " + error,
                mensaje_clase: "Error"
            })
          }
          this.setState({
            procesando: false
        })
    }

    cierraVentanaValor(){
        this.setState({
            pide_valor: false,
            campo_valor: 0
        })
    }

    valorTomado(monto){
        this.setState({
            pide_valor: false,
            monto_solicitado: parseFloat(monto),
            pide_confirmacion: true
        })
    }

    confirmaOk(){
        this.solicitar();
    }

    confirmaNo(){
        this.setState({
            pide_confirmacion: false
        })
    }

    confirmaOk2(){
        this.eliminar();
    }

    confirmaNo2(){
        this.setState({
            pide_confirmacion2: false
        })
    }


    cerrarMensaje(){
        this.setState({
            mensaje: ""
        })
        if (this.state.mensaje_proceso!=null){
            this.state.mensaje_proceso();
        }
    }

    cerrarProceso(){
        this.props.procesoCierre();
    }

    respuesta_correcta(){
        this.setState({
            pregunta_seguridad: false
        })
    }

    render(){
        var a_titulos = [
            {texto: 'Ahorro', ancho: '1fr'},
            {texto: 'Cuota', ancho: '1fr'},
            {texto: '¿Modificar?', ancho: '110px'},
            {texto: '¿Eliminar?', ancho: '110px'}
            ];
        var a_justifica = ['I', 'C', 'C', 'C'];
        
         var datos = {
            titulo: "",
            titulos: a_titulos,
            justifica: a_justifica,
            datos: this.state.datos
        }; 
        const obj_valor = (this.state.pide_valor?<PideValor texto="Introduzca el monto que desea ahorrar" evtCerrar={this.cierraVentanaValor} montoInicial={this.state.montoInicial} evtValor= {this.valorTomado} minimo={this.state.valor_minimo} maximo={this.state.valor_maximo}/>: null);
        const obj_confirmacion = (this.state.pide_confirmacion?<Confirmacion texto="¿Desea realizar este cambio de ahorro?" evtCerrar={this.confirmaNo} evtClick={this.confirmaOk} />: null);
        const obj_confirmacion2 = (this.state.pide_confirmacion2?<Confirmacion texto="¿Desea eliminar este ahorro?" evtCerrar={this.confirmaNo2} evtClick={this.confirmaOk2} />: null);
        const obj_wait = (this.state.procesando?<Wait />: null);
        const obj_mensaje = (this.state.mensaje.length>0?<Mensaje texto={this.state.mensaje} clase={this.state.mensaje_clase} evtCerrar={this.cerrarMensaje} />: null);
        const obj_seguridad = (this.state.pregunta_seguridad? <Pregunta respuesta_correcta={this.respuesta_correcta} procesoCierre={this.cerrarProceso} />: null);

        return(
            <div className="Div-ModAhorros">
                {obj_valor}
                {obj_seguridad}
                {obj_confirmacion}
                {obj_confirmacion2}
                {obj_mensaje}
                {obj_wait}
                <RowTabla data={datos} evtClick={this.evtClick} />
            </div>
        )
    }

}

export default AhorrosModifica;