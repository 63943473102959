import React from "react";
import './../css/Calculadora.css';
import MiSelect from './../components/MiSelect.jsx';
import MiBoton from './MiBoton';
import MiEdit from './MiEdit';
import Wait from './Wait';
import Mensaje from './Mensaje';
import MiInfoValor from './MiInfoValor';

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

class Calculadora extends React.Component{
    constructor(props){
        super(props);
        let a_anos = [];
        var a;
            for(a=1;a<=60;a++){
                let item = {codigo: a.toString(), nombre: a.toString(), padre: '0'}
                a_anos.push(item);
            }
        this.state = {
            tipo: '0',
            monto: '0.00',
            extraordinaria: '0.00',
            extraordinaria_mes: 0,
            cuotas: 0,
            label_cuotas: 'Cuotas:',
            datos_cuotas: a_anos,
            procesando: false,
            texto_mensaje: "",
            monto_cuota: "..."
        }

        this.props.titulo("Calculadora de Préstamo");

        this.evtChange = this.evtChange.bind(this);
        this.reviTipo = this.reviTipo.bind(this);
        this.evtClickCalcular = this.evtClickCalcular.bind(this);
        this.evtCerrarMensaje = this.evtCerrarMensaje.bind(this)

        this.reviTipo('0');

    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        });
        if (nombre=="tipo" || nombre=="monto" || nombre=="cuotas" || nombre=="extraordinaria"){
            this.setState({
                monto_cuota: "..."
            })
        }
        if (nombre=="tipo"){
            this.reviTipo(target.value);
        }
    }

    reviTipo(tipo){
        let a_anos = [];
        if (tipo=="20" || tipo=="24"){
            var a;
            for(a=1;a<=20;a++){
                let item = {codigo: a.toString(), nombre: a.toString(), padre: '0'}
                a_anos.push(item);
            }
            this.setState({
                label_cuotas: "Años:",
                datos_cuotas: a_anos
            });
        } else {
            var a;
            for(a=1;a<=60;a++){
                let item = {codigo: a.toString(), nombre: a.toString(), padre: '0'}
                a_anos.push(item);
            }
            this.setState({
                label_cuotas: "Cuotas:",
                datos_cuotas: a_anos
            });
        }
    }

    async evtClickCalcular(){
        if (!this.puedeCalcular()){
            return;
        }
        this.setState({
            procesando: true
        })
        let tk = "";
        try{
            tk = localStorage.getItem("tk");
        } catch(error){

        }
        var myHeaders = new Headers();
        myHeaders.append("tk", tk);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("tipo", this.state.tipo);
        miData.append("monto", this.state.monto);
        miData.append("cuotas", this.state.cuotas);
        miData.append("extraordinaria", this.state.extraordinaria);
        miData.append("extraordinaria_mes", this.state.extraordinaria_mes);
        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/prestamos/calculadora";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data.respuesta=="E"){
                    this.props.sesion_expirada();
                    return;
                } 

                if (data.respuesta=="S"){
                    this.setState({
                        procesando: false,
                        monto_cuota: parseFloat(data.cuota).format(2,3,',','.')
                    })

                } else {
                    this.setState({
                        texto_mensaje: data.mensaje,
                        procesando: false
                    })
                }
            } else {
                //alert("Error de conexión...");
                this.setState({
                    texto_mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    procesando: false
                })
            }
          } catch(error){
            //alert("Error: " + error);
            this.setState({
                texto_mensaje: "Ha ocurrido un error en la conexión. Por favor intente más tarde",
                procesando: false
            })
          }
    }
    evtCerrarMensaje(){
        this.setState({
            texto_mensaje: ""
        })
        
    }
    puedeCalcular(){
        let tipo = this.state.tipo;
        let monto = this.state.monto;
        let extra = this.state.extraordinaria;
        let extra_mes = this.state.extraordinaria_mes
        let cuotas = this.state.cuotas;
        let resultado = true;
        let mensaje = ""

        if (tipo=='0' && resultado){
            resultado = false;
            mensaje = "Debe seleccionar el tipo de préstamo";
        }
        if (parseFloat(monto)<1000 && resultado){
            resultado = false;
            mensaje = "Monto debe ser mayor de 1,000"
        }
        if (parseInt(cuotas)<1 && resultado){
            resultado = false;
            if (tipo=='20' || tipo=='24'){
                mensaje = "Debe seleccionar los años del préstamo"
            } else {
                mensaje = "Debe seleccionar las cuotas"
            }
        }
        if (tipo=="20" || tipo=="24"){
            if (parseFloat(extra)>0 && extra_mes == 0){
                resultado = false;
                mensaje = "Debe especificar el mes de la cuota extraordinaria"
            }
        }
        this.setState({
            texto_mensaje: mensaje
        })
        return resultado;
    }

    render(){
        const datos_tipos = [

            {codigo:"02", nombre: "Normal o Especial", padre: "0"},                                    
            {codigo:"07", nombre: "Expreso", padre: "0"},   
            {codigo:"08", nombre: "Ordenes", padre: "0"},        
            {codigo:"24", nombre: "Viviendas", padre: "0"}
        ];
        const datos_mes = [{codigo:1, nombre: "Enero", padre:0},
                            {codigo:2, nombre: "Febrero", padre:0},
                            {codigo:3, nombre: "Marzo", padre:0}  ,  
                            {codigo:4, nombre: "Abril", padre:0},
                            {codigo:5, nombre: "Mayo", padre:0},
                            {codigo:6, nombre: "Junio", padre:0},
                            {codigo:7, nombre: "Julio", padre:0},
                            {codigo:8, nombre: "Agosto", padre:0},
                            {codigo:9, nombre: "Septiembre", padre:0},
                            {codigo:10, nombre: "Octubre", padre:0},
                            {codigo:11, nombre: "Noviembre", padre:0},
                            {codigo:12, nombre: "Diciembre", padre:0}
                        ]

        const obj_extra = (this.state.tipo=='20' || this.state.tipo=='24'? 
        <div>
        <div className="RowsCalc">
            <div><label className='EtiqCalc'>Cuota Extraordinaria:</label></div>
            <MiEdit maximo={999999999.99} miNombre="extraordinaria" valor={this.state.extraordinaria} evtChange={this.evtChange} />
        </div>
        <div className="RowsCalc">
            <div><label className='EtiqCalc'>Mes Cuota Extraordinaria:</label></div>
            <MiSelect filas={datos_mes} padre={0} miNombre="extraordinaria_mes" evtCambios={this.evtChange} valor={this.state.extraordinaria_mes} />
        </div>
        </div>
        : null
        )
        const obj_cuota = (this.state.monto_cuota=="..."?null:
                <div className="RowsCalc" style={{gridTemplateColumns: '1fr 200px 1fr'}}>
                    <div></div>
                    <MiInfoValor valor={this.state.monto_cuota} label="Su cuota sería de..." />
                    <div></div>
                </div>
        )
        
        const obj_wait = (this.state.procesando?<Wait />: null);
        const obj_mensaje = (this.state.texto_mensaje.length>0 ? <Mensaje clase={this.state.clase_mensaje} texto={this.state.texto_mensaje} evtCerrar={this.evtCerrarMensaje} />:null);
        

        return(
            <div className="Div-Calc">
                {obj_wait}
                {obj_mensaje}
                <div className="RowsCalc">
                    <div><label className='EtiqCalc'>Tipo de Préstamo:</label></div>
                    <MiSelect filas={datos_tipos} padre={0} miNombre="tipo" evtCambios={this.evtChange} valor={this.state.tipo} />
                </div>
                <div className="RowsCalc">
                    <div><label className='EtiqCalc'>Monto:</label></div>
                    <MiEdit maximo={999999999.99} miNombre="monto" valor={this.state.monto} evtChange={this.evtChange} />
                </div>
                <div className="RowsCalc">
                    <div><label className='EtiqCalc'>{this.state.label_cuotas}</label></div>
                    <MiSelect ancho="200px" filas={this.state.datos_cuotas} padre={0} miNombre="cuotas" evtCambios={this.evtChange} valor={this.state.cuotas} />
                </div>
                {obj_extra}
                <div className="RowsCalc" style={{gridTemplateColumns: '1fr 200px 1fr', marginTop: '10px'}}>
                    <div></div>
                    <MiBoton texto="Calcular" miNombre="btCalcular" evtClick={this.evtClickCalcular} />
                    <div></div>
                </div>
                {obj_cuota}
                
            </div>
        )
    }
}

export default Calculadora;