import React from "react";
import "../css/Cursos.css";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useState, useEffect } from "react";

import Confirmacion from "./Confirmacion";

export default function Cursos(props) {
    const [participar, setParticipar] = useState(false)
    const [openConfirmacion, setOpenConfirmacion] = useState(false)

    useEffect(()=>{
        inicio();
    }, [])

    const handleParticipar = (event)=>{
        setOpenConfirmacion(true)
        
        setParticipar(event.target.checked)
    }

    const obj_confirmacion = ()=>{
        if (openConfirmacion){
            return (
                <Confirmacion texto={(!participar?"NO desea": "Desea") + " participar en " + props.nombre} 
                evtCerrar={()=>{setOpenConfirmacion(false); inicio()}}
                evtClick={handleGrabar}
                />
            )
        } else {
            return null;
        }
    }

    const inicio = async ()=>{
        let params = new FormData()
        params.append("cliente", parseInt(localStorage.getItem("cliente")));
        params.append("curso", props.curso);

        var options = {
            method: "POST",
            body: params
        }
        try {
            const response = await fetch("https://api.cooeprouasd.net/api/login/curso_inicio", options);
            if (response.ok) {
                const data = await response.json()
                console.log(data)
                if (data.respuesta=="S"){
                    setParticipar(parseInt(data.participa)==-1?true: false)
                }

            } 
        } catch (error) {
            //setMensaje(error)
        }
        
        
    }

    const handleGrabar = async ()=>{
        let params = new FormData()
        params.append("cliente", parseInt(localStorage.getItem("cliente")));
        params.append("curso", props.curso);
        params.append("participa", (participar? -1: 0));

        var options = {
            method: "POST",
            body: params
        }
        try {
            const response = await fetch("https://api.cooeprouasd.net/api/login/curso_grabar", options);
            if (response.ok) {
                const data = await response.json()
                console.log(data)
                if (data.respuesta=="S"){
                    setOpenConfirmacion(false)
                }

            } 
        } catch (error) {
            //setMensaje(error)
        }
        await inicio();
    }

  return (
    <div className="Cursos-div">
        {obj_confirmacion()}
      <div className="Cursos-marco">
        <div className="Cursos-titulo">
          {props.nombre}
        </div>
        <div className="Cursos-fecha">{props.fecha}</div>

        <div className="Cursos-opcion">
            <FormGroup>
                <FormControlLabel control={<Switch checked={participar} onChange={handleParticipar} />} label="Deseo participar!" />
            </FormGroup>
          
        </div>
      </div>
    </div>
  );
}
