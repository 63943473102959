import React from "react";
import '../css/TomaFoto.css';
import MiBoton from "./MiBoton";
import Wait from "./Wait";
import Mensaje from "./Mensaje";
import Confirmacion from "./Confirmacion";
import nophoto from '../images/nophoto.png';

class TomaFoto extends React.Component {
    constructor(props){
        super(props);

    }

    render(){

        return(
            <div className="Fondo-Foto">
                <div className="Div-Foto">
                    <div><img className='img-rounded img-thumbnail vacio' id="foto_documento" src={nophoto}/></div>
                    <div>
                        <label for="get_file" class="lblFotos">Subir foto...</label>
                        <input id="get_file" type="file" accept=".jpg,.png" onChange={this.muestraFoto}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default TomaFoto;