import React, { useState } from "react";
import "../css/LavadoActivos.css";
import MiEdit from "./MiEdit";
import MiSelect from "./MiSelect";
import MiBoton from "./MiBoton";
import Mensaje from "./Mensaje";

export default function LavadoActivos(props) {
  const [procesando, setProcesando] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [trabajoPublico, setTrabajoPublico] = useState(2);
  const [manejoRecursos, setManejoRecursos] = useState(2);
  const [aspiraPuestoPublico, setAspiraPuestoPublico] = useState(2);
  const [familiares, setFamiliares] = useState(2);
  const [familiaresDetalle, setFamiliaresDetalle] = useState("");
  const [otraActividad, setOtraActividad] = useState(2);
  const [otraActividadDetalle, setOtraActividadDetalle] = useState("");
  const [otraActividadIngresosRd, setOtraActividadIngresosRd] =
    useState("0.00");
  const [otraActividadIngresosUs, setOtraActividadIngresosUs] =
    useState("0.00");
  const [telefono, setTelefono] = useState("");
  const [otraProvincia, setOtraProvincia] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [provincia, setProvincia] = useState(0);
  const [trabajoPublicoInstitucion, setTrabajoPublicoInstitucion] =
    useState("");
  const datos_prov = [
    { codigo: 1, nombre: "Santo Domingo", padre: 0 },
    { codigo: 1, nombre: "Azua", padre: 0 },
    { codigo: 2, nombre: "Bahoruco", padre: 0 },
    { codigo: 3, nombre: "Barahona", padre: 0 },
    { codigo: 4, nombre: "Dajabón", padre: 0 },
    { codigo: 5, nombre: "Duarte", padre: 0 },
    { codigo: 6, nombre: "Elías Piña", padre: 0 },
    { codigo: 7, nombre: "El Seibo", padre: 0 },
    { codigo: 8, nombre: "Espaillat", padre: 0 },
    { codigo: 9, nombre: "Hato Mayor", padre: 0 },
    { codigo: 10, nombre: "Hermanas Mirabal", padre: 0 },
    { codigo: 11, nombre: "Independencia", padre: 0 },
    { codigo: 12, nombre: "La Altagracia", padre: 0 },
    { codigo: 13, nombre: "La Romana", padre: 0 },
    { codigo: 14, nombre: "La Vega", padre: 0 },
    { codigo: 15, nombre: "María Trinidad Sánchez", padre: 0 },
    { codigo: 16, nombre: "Monseñor Nouel", padre: 0 },
    { codigo: 17, nombre: "Montecristi", padre: 0 },
    { codigo: 18, nombre: "Monte Plata", padre: 0 },
    { codigo: 19, nombre: "Pedernales", padre: 0 },
    { codigo: 20, nombre: "Peravia", padre: 0 },
    { codigo: 21, nombre: "Puerto Plata", padre: 0 },
    { codigo: 22, nombre: "Samaná", padre: 0 },
    { codigo: 23, nombre: "Sánchez Ramírez", padre: 0 },
    { codigo: 24, nombre: "San Cristóbal", padre: 0 },
    { codigo: 25, nombre: "San José de Ocoa", padre: 0 },
    { codigo: 26, nombre: "San Juan", padre: 0 },
    { codigo: 27, nombre: "San Pedro de Macorís", padre: 0 },
    { codigo: 28, nombre: "Santiago", padre: 0 },
    { codigo: 29, nombre: "Santiago Rodríguez", padre: 0 },
    { codigo: 30, nombre: "Provincia Santo Domingo", padre: 0 },
    { codigo: 31, nombre: "Valverde", padre: 0 },
    { codigo: 32, nombre: "Distrito Nacional", padre: 0 },
    { codigo: 99, nombre: "Otra Localidad (especificar)", padre: 0 },
  ];

  const grabar = async () => {
    setProcesando(true);
    let params = new URLSearchParams();
    params.append("cliente", localStorage.getItem("cliente"));
    params.append("trabajo_publico", trabajoPublico);
    params.append("manejo_recursos", manejoRecursos);
    params.append("aspira_puesto_publico", aspiraPuestoPublico);
    params.append("familiares", familiares);
    params.append("familiares_detalle", familiaresDetalle);
    params.append("otra_actividad", otraActividad);
    params.append("otra_actividad_detalle", otraActividadDetalle);
    params.append("otra_actividad_ingresos_rd", otraActividadIngresosRd);
    params.append("otra_actividad_ingresos_us", otraActividadIngresosUs);
    params.append("provincia", provincia);
    params.append("telefono", telefono);
    params.append("celular", celular);
    params.append("email", email);
    params.append("provincia_otro", otraProvincia);

    var options = {
      method: "POST",
      body: params,
    };
    try {
      const response = await fetch(
        "https://api.cooeprouasd.net/api/lavado/graba",
        options
      );
      if (response.ok) {
        const data = await response.json();
        if (data.respuesta == "S") {
          props.cerrar();
        } else {
          console.log(data.mensaje);
          setMensaje(data.mensaje);
        }
      } else {
        console.log(response.statusText);
        setMensaje(response.statusText);
      }
    } catch (error) {
      console.log(error);
      setMensaje(error);
    }
    setProcesando(false);
  };

  const handleGrabar = () => {
    if (puedeGrabar()) {
      grabar();
    }
  };

  const puedeGrabar = () => {
    let resultado = true;

    if (telefono.length == 0 && resultado) {
      setMensaje("Debe digitar el teléfono");
      resultado = false;
    }
    if (celular.length == 0 && resultado) {
      setMensaje("Debe digitar el celular");
      resultado = false;
    }
    if (email.length == 0 && resultado) {
      setMensaje("Debe digitar su correo electrónico");
      resultado = false;
    }
    if (provincia == 0 && resultado) {
      setMensaje("Debe seleccionar su provincia");
      resultado = false;
    }

    return resultado;
  };

  const handleCloseMensaje = () => {
    setMensaje("");
  };

  return (
    <div className="Fondo-Lavado">
      <div className="Div-Lavado">
        {mensaje.length > 0 ? (
          <Mensaje
            texto={mensaje}
            clase="Error"
            evtCerrar={handleCloseMensaje}
          />
        ) : null}
        <div className="lavTitulo">Control de Lavado de Activos</div>
        <div className="lavTitulo2">
          <div style={{ textAlign: "center" }}>
            <h5>
              Dando cumplimiento a la Ley 155-17 sobre Lavado de Activos y
              Financiamiento del Terrorismo, por favor llene las siguientes
              informaciones:
            </h5>
          </div>
          <div>
            <MiBoton texto="Grabar" evtClick={handleGrabar} />
          </div>
        </div>

        <div
          style={{
            marginTop: "30px",
            textAlign: "left",
            borderBottom: "1px solid black",
            marginBottom: "10px",
          }}
        >
          <h6>Por favor, actualice las siguientes informaciones...</h6>
        </div>
        <div className="lavCols">
          <div>
            <div className="rowLab3">
              <div>
                <label className="form-label form-label-sm ">Teléfono:</label>
              </div>
              <div>
                <input
                  type="text"
                  autocomplete="off"
                  value={telefono}
                  style={{ maxWidth: "200px" }}
                  onChange={(event) => {
                    setTelefono(event.target.value);
                  }}
                  className="form-control form-control-sm newVal"
                />
              </div>
            </div>
            <div className="rowLab3">
              <div>
                <label className="form-label form-label-sm ">Celular:</label>
              </div>
              <div>
                <input
                  type="text"
                  autocomplete="off"
                  value={celular}
                  style={{ maxWidth: "200px" }}
                  onChange={(event) => {
                    setCelular(event.target.value);
                  }}
                  className="form-control form-control-sm newVal"
                />
              </div>
            </div>
            <div className="rowLab3">
              <div>
                <label className="form-label form-label-sm ">Email:</label>
              </div>
              <div>
                <input
                  type="text"
                  autocomplete="off"
                  value={email}
                  style={{ maxWidth: "300px" }}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  className="form-control form-control-sm newVal"
                />
              </div>
            </div>
          </div>
          <div>
            <div style={{ width: "100%" }}>
              <label className="newLabel">Provincia:</label>
            </div>
            <div style={{ width: "100%" }}>
              <MiSelect
                filas={datos_prov}
                padre={0}
                miNombre="provincia"
                evtCambios={(event) => {
                  setProvincia(event.target.value);
                }}
                ancho="400px"
                valor={provincia}
              />
            </div>
            {provincia == 99 ? (
              <>
                <div
                  className="rowLab"
                  style={{
                    gridTemplateColumns: "120px 1fr",
                    marginTop: "10px",
                  }}
                >
                  <div>
                    <label
                      for="lv_otra_provincia"
                      className="form-label form-label-sm "
                    >
                      Otra localidad:
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      autocomplete="off"
                      value={otraProvincia}
                      onChange={(event) => {
                        setOtraProvincia(event.target.value);
                      }}
                      className="form-control form-control-sm newVal"
                      name="lv_otra_provincia"
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            borderBottom: "1px solid black",
            marginBottom: "10px",
          }}
        >
          <h6>Responda las siguientes preguntas...</h6>
        </div>
        <div className="rowLab">
          <div>Sí</div>
          <div>No</div>
          <div></div>
        </div>
        <div className="rowLab ">
          <div>
            <input
              type="radio"
              className=" "
              checked={trabajoPublico == 1}
              onChange={(event) => {
                setTrabajoPublico(event.target.value);
              }}
              id="lv_trabajo_publico_si"
              value="1"
              name="lv_trabajo_publico"
            />
          </div>
          <div>
            <input
              type="radio"
              className=" "
              checked={trabajoPublico == 2}
              onChange={(event) => {
                setTrabajoPublico(event.target.value);
              }}
              id="lv_trabajo_publico_no"
              value="2"
              name="lv_trabajo_publico"
            />
          </div>
          <div>
            <label class="form-label" for="lv_trabajo_publico">
              ¿Ha trabajado en el sector público en los últimos 3 años? (no
              incluye su trabajo en la UASD)
            </label>
          </div>
        </div>

        <div className="rowLab rowLab4">
          <div>
            <label
              for="lv_trabajo_publico_institucion"
              className="form-label form-label-sm "
            >
              Institución:
            </label>
          </div>
          <div>
            <input
              type="text"
              autocomplete="off"
              value={trabajoPublicoInstitucion}
              onChange={(event) => {
                setTrabajoPublicoInstitucion(event.target.value);
              }}
              className="form-control form-control-sm newVal"
              name="lv__trabajo_publico_institucion"
            />
          </div>
        </div>
        <div className=" rowLab">
          <div>
            <input
              type="radio"
              className=" "
              checked={manejoRecursos == 1}
              onChange={(event) => {
                setManejoRecursos(event.target.value);
              }}
              id="lv_manejo_recursos_si"
              value="1"
              name="lv_manejo_recursos"
            />
          </div>
          <div>
            <input
              type="radio"
              className=" "
              checked={manejoRecursos == 2}
              onChange={(event) => {
                setManejoRecursos(event.target.value);
              }}
              id="lv_manejo_recursos_no"
              value="2"
              name="lv_manejo_recursos"
            />
          </div>
          <div>
            <label class="form-label" for="lv_manejo_recursos">
              ¿Ha manejado o maneja recursos públicos durante los últimos 3
              años?
            </label>
          </div>
        </div>
        <div className=" rowLab">
          <div>
            <input
              type="radio"
              className=" "
              checked={aspiraPuestoPublico == 1}
              onChange={(event) => {
                setAspiraPuestoPublico(event.target.value);
              }}
              id="lv_aspira_puesto_publico_si"
              value="1"
              name="lv_aspira_puesto_publico"
            />
          </div>
          <div>
            <input
              type="radio"
              className=" "
              checked={aspiraPuestoPublico == 2}
              onChange={(event) => {
                setAspiraPuestoPublico(event.target.value);
              }}
              id="lv_aspira_puesto_publico_no"
              value="2"
              name="lv_aspira_puesto_publico"
            />
          </div>
          <div>
            <label class="form-label" for="lv_aspira_puesto_publico">
              ¿Aspira a algún puesto político actualmente?
            </label>
          </div>
        </div>
        <div className=" rowLab">
          <div>
            <input
              type="radio"
              className=" "
              checked={familiares == 1}
              onChange={(event) => {
                setFamiliares(event.target.value);
              }}
              id="lv_familiares_si"
              value="1"
              name="lv_familiares"
            />
          </div>
          <div>
            <input
              type="radio"
              className=" "
              checked={familiares == 2}
              onChange={(event) => {
                setFamiliares(event.target.value);
              }}
              id="lv_familiares_no"
              value="2"
              name="lv_familiares"
            />
          </div>
          <div>
            <label class="form-label" for="lv_familiares">
              ¿Alguna de las preguntas anteriores aplica a un familiar?
            </label>
          </div>
        </div>
        <div className="rowLab rowLab4">
          <div>
            <label
              for="lv_familiares_detalle"
              className="form-label form-label-sm "
            >
              Especifique:
            </label>
          </div>
          <div>
            <input
              type="text"
              autocomplete="off"
              value={familiaresDetalle}
              onChange={(event) => {
                setFamiliaresDetalle(event.target.value);
              }}
              className="form-control form-control-sm newVal"
              name="lv_familiares_detalle"
            />
          </div>
        </div>
        <div className=" rowLab">
          <div>
            <input
              type="radio"
              className=" "
              checked={otraActividad == 1}
              onChange={(event) => {
                setOtraActividad(event.target.value);
              }}
              id="lv_otra_actividad_si"
              value="1"
              name="lv_otra_actividad"
            />
          </div>
          <div>
            <input
              type="radio"
              className=" "
              checked={otraActividad == 2}
              onChange={(event) => {
                setOtraActividad(event.target.value);
              }}
              id="lv_otra_actividad_no"
              value="2"
              name="lv_otra_actividad"
            />
          </div>
          <div>
            <label class="form-label" for="lv_otra_actividad">
              ¿Realiza alguna otra actividad que le genere ingresos?
            </label>
          </div>
        </div>
        <div className="rowLab rowLab4" npm run build>
          <div>
            <label
              for="lv_otra_actividad_detalle"
              className="form-label form-label-sm "
            >
              Especifique:
            </label>
          </div>
          <div>
            <input
              type="text"
              autocomplete="off"
              className="form-control form-control-sm newVal"
              onChange={(event) => {
                setOtraActividadDetalle(event.target.value);
              }}
              value={otraActividadDetalle}
              name="lv_otra_actividad_detalle"
            />
          </div>
        </div>
        <div className="rowLab2">
          <div>
            <label
              for="lv_otra_actividad_ingresos_rd"
              class="form-label form-label-sm lbls"
            >
              Ingresos Otra Actividad RD$:
            </label>
          </div>
          <MiEdit
            valor={otraActividadIngresosRd}
            evtChange={(event) => {
              setOtraActividadIngresosRd(event.target.value);
            }}
            miNombre="lv_otra_actividad_ingresos_rd"
          />
        </div>
        <div className="rowLav2">
          <div>
            <label
              for="lv_otra_actividad_ingresos_us"
              class="form-label form-label-sm lbls"
            >
              Ingresos Otra Actividad US$:
            </label>
          </div>
          <MiEdit
            valor={otraActividadIngresosUs}
            evtChange={(event) => {
              setOtraActividadIngresosUs(event.target.value);
            }}
            miNombre="lv_otra_actividad_ingresos_us"
          />
        </div>
      </div>
    </div>
  );
}
