import react from "react";
import "../css/Reel.css";
import { useState, useEffect, useRef } from "react";
import ReelView from "./ReelView";


export default function Reel(props) {
    const [paso, setPaso] = useState(1)
    const [rutaImagen, setRutaImagen] = useState("")
    

    useEffect(()=>{
        /*var x = setInterval(function() {
            //console.log("entre")
            let nuevo = paso
            if (nuevo==3){
                nuevo = 1
            }else {
                nuevo++
            }
            setPaso(nuevo)
            
        }, 5000) */
    }, [])

    useEffect(()=>{
        setRutaImagen("https://api.cooeprouasd.net/api/videos/verimg?token=" + props.token + "&cual=" + paso)
    }, [paso])

  return (
    <div className="reel">
      <div className="reel-circle" onClick={()=>{props.abre()}}>
        <img src={rutaImagen} />

      </div>
      <div className="reel-titulo">{props.titulo}</div>
    </div>
  );
}
