import React from 'react';
import './../css/Wait.css';

class Wait extends React.Component {
    constructor(props){
        super(props);
    }

    render(){

        return(
            <div className="Fondo-Wait">
                <div className="Div-Wait"></div>
            </div>
        )
    }
}

export default Wait;