import React from 'react';
import './../css/PideValor.css';
import MiBoton from './MiBoton';
import MiEdit from './MiEdit';
import Mensaje from './Mensaje';

class PideValor extends React.Component {
    constructor(props){
        super(props);

        let montoInicial = this.props.montoInicial || "0.00"

        this.state = {
            visible: "fade-in-pide",
            valor: montoInicial,
            mensaje: "",
            clase_mensaje: "Error"
        }
        this.cerrarDialogo = this.cerrarDialogo.bind(this);
        this.procesosCierre = this.procesosCierre.bind(this);
        this.evtCambios = this.evtCambios.bind(this);
        this.valorTomado = this.valorTomado.bind(this);
        this.cerrarMensaje = this.cerrarMensaje.bind(this)
    }

    cerrarDialogo(event){
        this.setState({
            visible: "fade-out-pide",
            visible_contenido: 0
        })
        setTimeout(()=>this.procesosCierre(), 100);
    }

    valorTomado(){
        if (this.puedeValor()){
            this.props.evtValor(this.state.valor);
        }

    }

    puedeValor(){
        let puede = true;
        let minimo = parseFloat(this.props.minimo);
        let maximo = parseFloat(this.props.maximo);
        let valor = parseFloat(this.state.valor);
        if (valor>maximo){
            puede = false;
            this.setState({
                mensaje: "Monto mayor al disponible"
            });
        }
        if (valor<minimo){
            puede = false;
            this.setState({
                mensaje: "Monto incorrecto"
            });
        }
        return puede;
    }

    procesosCierre(){
        this.props.evtCerrar();
        this.setState({
            visible: "fade-Out-pide"

        })
    }

    evtCambios(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        })
    }

    cerrarMensaje(){
        this.setState({
            mensaje: ""
        })
    }

    render(){
        const obj_mensaje = (this.state.mensaje.length>0?<Mensaje texto={this.state.mensaje} clase={this.state.clase_mensaje} evtCerrar={this.cerrarMensaje} />: null);
        return(
            <div className="Fondo-Mensaje" >
                {obj_mensaje}
                <div className={"Div-Pide " + this.state.visible}  >
                    <div className="boton-cerrar" onClick={this.cerrarDialogo}>X</div>
                    <div className="Div-Titulo">
                        <div className="Div-Titulo-Icon"></div>
                        <div>?</div>
                    </div>
                    <div className="Div-Mensaje-Texto">{this.props.texto}</div>
                    <div className="Div-Edit">
                        <div></div>
                        <MiEdit valor={this.state.valor} miNombre="valor" evtChange={this.evtCambios}  />
                        <div></div>
                    </div>
                    
                    <div className="Div-Mensaje-Boton">
                        <div></div>
                        <MiBoton  texto="Ok" miNombre="btConfirmarOk" evtClick={this.valorTomado} />
                        <div></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PideValor;