import React from "react";
import './../css/DetallePrestamos.css';
import MiSelect from './../components/MiSelect.jsx';
import MiBoton from './MiBoton';
import MiEdit from './MiEdit';
import Wait from './Wait';
import Mensaje from './Mensaje';
import MiInfoValor from './MiInfoValor';

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

class DetallePrestamos extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            datos_prestamos: [],
            prestamo: 0,
            tipo: '',
            plazo: '',
            fechainicio: '',
            fechafin: '',
            monto: '0.00',
            intereses: '0.00',
            cuota: '0.00',
            cuotacap: '0.00',
            cuotaint: '0.00',
            deudaplazo: '0.00',
            deudasaldo: '0.00',
            procesando: false,
            texto_mensaje: ""
        }

        this.evtChange = this.evtChange.bind(this);
        this.actPrestamos = this.actPrestamos.bind(this);
        this.evtCerrarMensaje = this.evtCerrarMensaje.bind(this);
        this.actDatos = this.actDatos.bind(this);

        this.props.titulo("Detalle de Préstamos");
        this.actPrestamos();

    }
 
    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        });
        if (nombre=="prestamo"){
            this.actDatos(target.value)
        }
    }

    async actPrestamos(){
        var a_pr = [];
        let tk = "";
        try{
            tk = localStorage.getItem("tk");
        } catch(error){

        }
        var myHeaders = new Headers();
        myHeaders.append("tk", tk);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cual", localStorage.getItem("cliente"));

        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/prestamos/vigentes";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data[0].respuesta=="S"){
                    var a;
                    for(a=0;a<data.length; a++){
                        let item = {codigo: data[a].codigo.toString(), nombre: data[a].nombre, padre: '0'}
                        
                        a_pr.push(item);
                    }
                    
                    this.setState({
                        datos_prestamos: a_pr
                    });
                } else {
                    //alert("mensaje: " + data.mensaje)
                    
                }
            } else {
                //alert("Error de conexión...");
            }
          } catch(error){
            //alert("Error: " + error);
          }
    }


    async actDatos(prestamo){
        if (prestamo==0){
            return;
        }
        this.setState({
            procesando: true
        })
        let tk = "";
        try{
            tk = localStorage.getItem("tk");
        } catch(error){

        }
        var myHeaders = new Headers();
        myHeaders.append("tk", tk);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cual", prestamo);
        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/prestamos/detalle";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data.respuesta=="E"){
                    this.props.sesion_expirada();
                    return;
                } 

                if (data.respuesta=="S"){
                    this.setState({
                        procesando: false,
                        tipo: data.tipo,
                        plazo: data.plazo + " cuota(s)",
                        fechainicio: data.fechainicio,
                        fechafin: data.fechafin,
                        monto: parseFloat(data.monto).format(2,3,',','.'),
                        intereses: parseFloat(data.intereses).format(2,3,',','.'),
                        cuota: parseFloat(data.cuota).format(2,3,',','.'),
                        cuotacap: parseFloat(data.cuotacap).format(2,3,',','.'),
                        cuotaint: parseFloat(data.cuotaint).format(2,3,',','.'),
                        deudaplazo: parseFloat(data.deudaplazo).format(2,3,',','.'),
                        deudasaldo: parseFloat(data.saldo).format(2,3,',','.')
                    })

                } else {
                    this.setState({
                        texto_mensaje: data.mensaje,
                        procesando: false
                    })
                }
            } else {
                //alert("Error de conexión...");
                this.setState({
                    texto_mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    procesando: false
                })
            }
          } catch(error){
            //alert("Error: " + error);
            this.setState({
                texto_mensaje: "Ha ocurrido un error en la conexión. Por favor intente más tarde",
                procesando: false
            })
          }
    }
    evtCerrarMensaje(){
        this.setState({
            texto_mensaje: ""
        })
        
    }


    render(){
 


        const obj_wait = (this.state.procesando?<Wait />: null);
        const obj_mensaje = (this.state.texto_mensaje.length>0 ? <Mensaje clase={this.state.clase_mensaje} texto={this.state.texto_mensaje} evtCerrar={this.evtCerrarMensaje} />:null);
        
        const obj_datos = (this.state.prestamo>0?
            <div>
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Tipo:</label></div>
                    <div className="DatosCalc">{this.state.tipo}</div>
                </div>
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Plazo:</label></div>
                    <div className="DatosCalc">{this.state.plazo}</div>
                </div>
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Fecha Inicio:</label></div>
                    <div className="DatosCalc">{this.state.fechainicio}</div>
                </div>
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Fecha Término:</label></div>
                    <div className="DatosCalc">{this.state.fechafin}</div>
                </div>
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Monto Préstamo:</label></div>
                    <div className="DatosCalc">{this.state.monto}</div>
                </div>
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Total Intereses:</label></div>
                    <div className="DatosCalc">{this.state.intereses}</div>
                </div>
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Cuota:</label></div>
                    <div className="DatosCalc">{this.state.cuota}</div>
                </div>
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Capital Cuota:</label></div>
                    <div className="DatosCalc">{this.state.cuotacap}</div>
                </div>
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Interés Cuota:</label></div>
                    <div className="DatosCalc">{this.state.cuotaint}</div>
                </div>
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Saldo Término:</label></div>
                    <div className="DatosCalc">{this.state.deudaplazo}</div>
                </div>
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Para Saldar:</label></div>
                    <div className="DatosCalc">{this.state.deudasaldo}</div>
                </div>
            </div>: null
            )

        return(
            <div className="Div-Det">
                {obj_wait}
                {obj_mensaje}
                <div className="RowsDet">
                    <div><label className='EtiqCalc'>Préstamo a consultar:</label></div>
                    <MiSelect filas={this.state.datos_prestamos} padre={0} miNombre="prestamo" evtCambios={this.evtChange} valor={this.state.prestamo} />
                </div>
                {obj_datos}
            </div>
        )
    }
}

export default DetallePrestamos;