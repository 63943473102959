import React, { useEffect, useState, Fragment } from "react";
import "../css/Maraton.css";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Switch,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  CircularProgress,
  Backdrop,
  IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function Maraton(props) {
  const [inscrito, setInscrito] = useState(false);
  const [enfermedad, setEnfermedad] = useState("");
  const [size, setSize] = useState("M");
  const [sangre, setSangre] = useState("");
  const [contacto, setContacto] = useState("");
  const [procesando, setProcesando] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [openConfirmacion, setOpenConfirmacion] = useState(false);

  useEffect(() => {
    inicio();
  }, []);

  useEffect(() => {
    if (!inscrito) {
      setSangre("");
      setContacto("");
      setSize("");
      setEnfermedad("");
    }
  }, [inscrito]);

  const objWait = () => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={procesando}
            onClick={() => { setProcesando(false) }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

  const handleCloseMensaje = () => {
    setMensaje("")
}
const actionMsg = (
    <Fragment>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseMensaje}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    </Fragment>
);

  const confirmaGrabar = () => {
    return (
      <Dialog open={openConfirmacion} fullWidth>
        <DialogTitle>Maratón Andrés Doñé</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={inscrito}
                      onChange={(event) => {
                        setInscrito(event.target.checked);
                      }}
                    />
                  }
                  label="Deseo participar"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <div className="Maraton-info">
                Para poder participar,<br />suminístrenos las siguientes
                informaciones...
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                type="text"
                label="Tipo de Sangre"
                disabled={!inscrito}
                onChange={(event) => {
                  setSangre(event.target.value);
                }}
                value={sangre}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                type="text"
                label="Teléfono Contacto familiar"
                helperText="Teléfono contacto"
                disabled={!inscrito}
                onChange={(event) => {
                  setContacto(event.target.value);
                }}
                value={contacto}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="label-size">Size:</InputLabel>
                <Select
                  labelId="label-size"
                  label="Size"
                  value={size}
                  disabled={!inscrito}
                  onChange={(event) => {
                    setSize(event.target.value);
                  }}
                >
                  <MenuItem value={"S"}>Small</MenuItem>
                  <MenuItem value={"M"}>Medium</MenuItem>
                  <MenuItem value={"L"}>Large</MenuItem>
                  <MenuItem value={"XL"}>X-Large</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                multiline
                rows={2}
                disabled={!inscrito}
                type="text"
                label="Padece alguna condición de salud?"
                helperText="Diabetes, presion arterial, etc."
                onChange={(event) => {
                  setEnfermedad(event.target.value);
                }}
                value={enfermedad}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmacion(false);
              inicio();
            }}
          >
            Cancelar
          </Button>
          <Button onClick={handleGrabar}>Registrar</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const inicio = async () => {
    setProcesando(true);

    try {
      let params = new URLSearchParams();
      params.append("cliente", localStorage.getItem("cliente"));

      var options = {
        method: "POST",
        body: params,
      };
      const response = await fetch(
        "https://api.cooeprouasd.net/api/login/maraton_inicio",
        options
      );
      if (response.ok) {
        const data = await response.json();
        if (data.respuesta == "S") {
          setInscrito(parseInt(data.inscrito) == 0 ? false : true);
          setEnfermedad(data.enfermedad);
          setSangre(data.tiposangre);
          setContacto(data.contacto);
          setSize(data.size);
        } else {
          setMensaje(data.mensaje);
        }
      } else {
        setMensaje(response.statusText);
      }
    } catch (error) {
      setMensaje(error);
    }
    setProcesando(false);
  };

  const puedeGrabar = () => {
    let resultado = true;
    if (inscrito && sangre.length == 0) {
      resultado = false;
      setMensaje("Debe digitar su tipo de sangre");
    }
    if (inscrito && contacto.length == 0) {
      resultado = false;
      setMensaje("Debe digitar un contacto familiar");
    }
    if (inscrito && size == "") {
      resultado = false;
      setMensaje("Debe seleccionar el size de camiseta");
    }

    return resultado;
  };

  const handleGrabar = () => {
    if (puedeGrabar()) {
      grabar();
    }
  };

  const grabar = async () => {
    setProcesando(true);
    setOpenConfirmacion(false)
    try {
      let params = new URLSearchParams();
      params.append("cliente", localStorage.getItem("cliente"));
      params.append("inscrito", (inscrito?-1: 0));
      params.append("enfermedad", enfermedad);
      params.append("tiposangre", sangre);
      params.append("size", size);
      params.append("contacto", contacto);

      var options = {
        method: "POST",
        body: params,
      };
      const response = await fetch(
        "https://api.cooeprouasd.net/api/login/maraton_grabar",
        options
      );
      if (response.ok) {
        const data = await response.json();
        if (data.respuesta == "S") {
          inicio();
        } else {
          setMensaje(data.mensaje);
        }
      } else {
        setMensaje(response.statusText);
      }
    } catch (error) {
      setMensaje(error);
    }
    setProcesando(false);
  };

  return (
    <div className="Maraton-cuerpo">
      <Snackbar
        open={mensaje.length == 0 ? false : true}
        autoHideDuration={6000}
        onClose={handleCloseMensaje}
        action={actionMsg}
        message={mensaje}
      />
      {objWait()}
      {confirmaGrabar()}
      <div className="Maraton-marco">
        <div className="div-imagen">
          <img src="maraton.jpeg" />
        </div>
        <div className="Maraton-marco-textos">
          <div className="Maraton-marco-texto1">
            7Ma. Edición Carrera Ecológica Andrés Doñé
          </div>
          <div className="Maraton-marco-texto2">
            Sábado 23 de noviembre, 8:00 a.m.
          </div>
          <div className="Maraton-marco-boton">
            <Button
              variant="contained"
              onClick={() => {
                setOpenConfirmacion(true);
              }}
            >
              {inscrito ? "Ya no quiero participar" : "Quiero Participar"}
            </Button>
          </div>
          <div className="Maraton-marco-inscrito">
            {inscrito ? "Estoy inscrito!!!" : ""}
          </div>
        </div>
      </div>
    </div>
  );
}
