import React from "react";
import logo from "./images/logo1.png";
//import close_img from "./images/close.svg";
import "./App.css";
import Resumen from "./components/Resumen";
import LineChart from "./components/Chart_line";
import PanelDetalle from "./components/Panel_detalle";
import PreguntasLlenar from "./components/PreguntasLlenar";

import MenuLeft from "./components/MenuLeft";
import MiDialog from "./components/MiDialog";

import "./css/bootstrap.min.css";
import Login from "./components/Login";
import CambioClave from "./components/CambioClave";
//import './js/bootstrap.min.js';
import RecuperacionClave from "./components/RecuperacionClave";
import NavEntrega from "./components/NavEntrega";
import anuncio_navidad from "./images/cerdito.png";
import anuncio_sorteo from "./images/sorteo.png";

import Excedentes from "./components/Excedentes";
import MiBoton from "./components/MiBoton";

import Whatsapp from "./components/Whatsapp.jsx";
import Cursos from "./components/Cursos.jsx";

import logo_live from "./images/logo_coop_live.png";
import Confirmacion from "./components/Confirmacion";
import Wait from "./components/Wait";
import Storys from "./components/Storys";

import CloseIcon from '@mui/icons-material/Close';

import LavadoActivos from "./components/LavadoActivo";

import imgCamp from "../src/images/campamento.png";
import Campamento from "./components/Campamento";
import imgReservado from "../src/images/reservado.png";
import { TryOutlined } from "@mui/icons-material";
import Maraton from "./components/Maraton";

class App extends React.Component {
  constructor(props) {
    super(props);
    let piden_recuperacion = false;
    let recuperacion_sesion = "";
    let piden_preguntas = false;
    let preguntas_sesion = "";
    let nav_entrega = false;
    let excedentes = false;
    let token = "";
    let opcion = 0;
    let token_sesion = "";
    let txt_disp = "PC";

    let date_ob = new Date();
    let hora = date_ob.getHours();
    let m_soyiphone = false;
    var url = window.location.href;
    var params = url.split("?");
    if (params.length > 1) {
      var opciones = params[1].split("&");
      var a;
      for (a = 0; a < opciones.length; a++) {
        let partes = opciones[a].split("=");
        if (partes.length > 1) {
          if (partes[0] == "nl") {
            piden_recuperacion = true;
            recuperacion_sesion = partes[1];
          }
          if (partes[0] == "pg") {
            piden_preguntas = true;
            preguntas_sesion = partes[1];
          }
          if (partes[0] == "nv") {
            //nav_entrega = true;
            token = partes[1];
          }
          if (partes[0] == "op") {
            opcion = partes[1];
          }
          if (partes[0] == "tk") {
            token_sesion = partes[1];
            localStorage.setItem("tk", token_sesion);
            console.log("tengo un token: " + token_sesion);
          }
          if (partes[0] == "disp") {
            txt_disp = partes[1];
            if (txt_disp == "iphone") {
              m_soyiphone = true;
            }
          }
        }
      }
    }
    let m_soyAndroid = false;
    try {
      m_soyAndroid = window.Android.soyAndroid();
      console.log("SOY ANDROID: " + m_soyAndroid);
    } catch (error) {
      m_soyAndroid = false;
      console.log("No estoy en un celular");
    }
    this.state = {
      menu_estatus: "oculto",
      dialog_estatus: "N",
      dialog_proceso: 1,
      dialog_ini: 0,
      sesionActiva: false,
      cambioClave: false,
      cambioClaveMensaje: "",
      cambioClaveCliente: 0,
      nombre_socio: localStorage.getItem("nombre"),
      socio: localStorage.getItem("socio"),
      cliente: parseInt(localStorage.getItem("cliente")),
      saludo:
        hora < 12
          ? "Buenos días, "
          : hora > 16
            ? "Buenas noches, "
            : "Buenas tardes, ",
      piden_recuperacion: piden_recuperacion,
      recuperacion_sesion: recuperacion_sesion,
      piden_preguntas: piden_preguntas,
      preguntas_sesion: preguntas_sesion,
      nav_entrega: nav_entrega,
      excedentes: excedentes,
      nav_opcion: opcion,
      token: token,

      pide_lavado: false,

      muestra_navidad: false,

      sorteo_renuncia: false,
      sorteo_participa: 1,
      sorteo_confirma: false,

      procesando: false,
      logueado: true,
      soyAndroid: m_soyAndroid,
      soyIphone: m_soyiphone,
      menu_user: "",
      pregunta_excedentes: false,
      excedentes_monto: 0.0,
      pregunta_whatsapp: false,
      whatsapp: "",
      link_stream: "",
      texto_stream: "",
      viendo_stream: false,

      campamento_infantil: false,
      abre_campamento: false,
      campamento_reservado: false,
      muestra_curso: false,
      curso_actual: 0,
      curso_nombre: "Curso Básico de Cooperativismo para Socios",
      curso_fecha: "3 de Octubre, 2024. 10:00 a.m.",
      mostrar_maraton: false
    };
    this.abreMenu = this.abreMenu.bind(this);
    this.abreDialog = this.abreDialog.bind(this);
    this.cierraDialog = this.cierraDialog.bind(this);
    this.revisaSesionActiva = this.revisaSesionActiva.bind(this);
    this.cierraSesion = this.cierraSesion.bind(this);
    this.abreSesion = this.abreSesion.bind(this);
    this.nuevaClave = this.nuevaClave.bind(this);
    this.finCambioClave = this.finCambioClave.bind(this);
    this.cierreRecuperacion = this.cierreRecuperacion.bind(this);
    this.cierrePreguntas = this.cierrePreguntas.bind(this);
    this.cierreExcedentes = this.cierreExcedentes.bind(this);
    this.clickNavidad = this.clickNavidad.bind(this);
    this.tiene_navidad = this.tiene_navidad.bind(this);
    this.sesion_expirada = this.sesion_expirada.bind(this);
    this.PantallaInicial = this.PantallaInicial.bind(this);
    this.ChequeaExpiracion = this.ChequeaExpiracion.bind(this);
    this.clickUser = this.clickUser.bind(this);
    this.clickCierraSesion = this.clickCierraSesion.bind(this);
    this.tiene_excedentes = this.tiene_excedentes.bind(this);
    this.clickDividendo = this.clickDividendo.bind(this);
    this.cierreWhatsapp = this.cierreWhatsapp.bind(this);
    this.click_stream = this.click_stream.bind(this);
    this.click_cerrar_stream = this.click_cerrar_stream.bind(this);
    this.click_max_stream = this.click_max_stream.bind(this);
    this.ver_stream = this.ver_stream.bind(this);
    this.cierreNavidad = this.cierreNavidad.bind(this);
    this.sorteo_cambios = this.sorteo_cambios.bind(this);
    this.sorteo_cancelar = this.sorteo_cancelar.bind(this);
    this.sorteo_confirmar = this.sorteo_confirmar.bind(this);
    this.sorteo_mostrar = this.sorteo_mostrar.bind(this);
    this.sorteo_participar = this.sorteo_participar.bind(this);
    this.close_lavado = this.close_lavado.bind(this);
    this.abreCampamento = this.abreCampamento.bind(this);
    this.cierraCampamento = this.cierraCampamento.bind(this);
    this.campamento_reservado = this.campamento_reservado.bind(this);
    if (!piden_preguntas && !piden_recuperacion) {
      this.PantallaInicial();
    }

    //this.tiene_navidad();
  }

  async PantallaInicial() {
    let estaLogueado = false;
    estaLogueado = await this.revisaSesionActiva();
    if (estaLogueado) {
      console.log("EESTA LOGUEADO");
      this.setState({
        nombre_socio: localStorage.getItem("nombre"),
        socio: localStorage.getItem("socio"),
        cliente: parseInt(localStorage.getItem("cliente")),
        logueado: true,
      });
      //this.tiene_excedentes();
      //this.tiene_navidad();
      this.lavado_pendiente();
      this.sorteo_mostrar();
      this.tengo_whatsapp();
      this.hay_stream();
      //this.campamento_reservado();
      setTimeout(() => this.abreMenu(), 2000);
    } else {
      console.log("NO ESTA LOGUEADO");
      this.cierraSesion();
    }
  }

  async ChequeaExpiracion() {
    let estaLogueado = false;
    estaLogueado = await this.revisaSesionActiva();
    let recuperando = this.state.piden_recuperacion;
    let preguntas = this.state.piden_preguntas;
    if (!recuperando && !preguntas) {
      if (estaLogueado) {
        this.setState({
          nombre_socio: localStorage.getItem("nombre"),
          socio: localStorage.getItem("socio"),
          logueado: true,
        });
      } else {
        this.cierraSesion2();
      }
    }
  }

  cierraSesion() {
    let recuperando = this.state.piden_recuperacion;
    let preguntas = this.state.piden_preguntas;
    let soyAndroid = this.state.soyAndroid;
    let date_ob = new Date();
    let hora = date_ob.getHours();

    if (!recuperando && !preguntas) {
      localStorage.setItem("cliente", 0);
      localStorage.setItem("tk", "");
      this.setState({
        menu_estatus: "oculto",
        dialog_estatus: "N",
        dialog_proceso: 1,
        dialog_ini: 0,
        sesionActiva: false,
        cambioClave: false,
        cambioClaveMensaje: "",
        cambioClaveCliente: 0,
        nombre_socio: "",
        socio: "",
        cliente: 0,
        saludo:
          hora < 12
            ? "Buenos días, "
            : hora > 16
              ? "Buenas noches, "
              : "Buenas tardes, ",
        piden_recuperacion: false,
        recuperacion_sesion: "",
        piden_preguntas: false,
        preguntas_sesion: "",
        nav_opcion: 0,
        muestra_navidad: false,
        procesando: false,
        logueado: false,
        menu_user: "",
        pregunta_whatsapp: false,
        whatsapp: "",
      });
    }
    if (soyAndroid) {
      try {
        window.Android.AbreLogin();
      } catch (error) {
        console.log(error);
      }
    }
    if (this.state.soyIphone) {
      try {
        window.webkit.messageHandlers.logout.postMessage("frio");
      } catch (error) { }
    }
  }

  cierraSesion2() {
    let recuperando = this.state.piden_recuperacion;
    let preguntas = this.state.piden_preguntas;
    let soyAndroid = this.state.soyAndroid;
    let date_ob = new Date();
    let hora = date_ob.getHours();

    if (!recuperando && !preguntas) {
      this.setState({
        menu_estatus: "oculto",
        dialog_estatus: "N",
        dialog_proceso: 1,
        dialog_ini: 0,
        sesionActiva: false,
        cambioClave: false,
        cambioClaveMensaje: "",
        cambioClaveCliente: 0,
        nombre_socio: "",
        socio: "",
        cliente: 0,
        saludo:
          hora < 12
            ? "Buenos días, "
            : hora > 16
              ? "Buenas noches, "
              : "Buenas tardes, ",
        piden_recuperacion: false,
        recuperacion_sesion: "",
        piden_preguntas: false,
        preguntas_sesion: "",
        nav_entrega: false,
        nav_opcion: 0,
        token: "",
        muestra_navidad: false,
        procesando: false,
        logueado: false,
        menu_user: "",
      });
    }
    if (soyAndroid) {
      try {
        window.Android.AbreLogin();
      } catch (error) {
        console.log(error);
      }
    }
    if (this.state.soyIphone) {
      try {
        window.webkit.messageHandlers.logout.postMessage("caliente");
      } catch (error) { }
    }
  }

  fnSoyIphone() {
    let resultado = false;
    try {
      let dispositivo = document.getElementById("dispositivo").value;
      if (dispositivo == "iphone") {
        resultado = true;
      }
    } catch (error) { }
    return resultado;
  }

  finCambioClave() {
    localStorage.setItem("cliente", 0);
    this.setState({
      sesionActiva: false,
      cambioClave: false,
      cambioClaveMensaje: "",
      cambioClaveCliente: 0,
    });
  }

  abreSesion() {
    this.setState({
      sesionActiva: true,
      nombre_socio: localStorage.getItem("nombre"),
      socio: localStorage.getItem("socio"),
      logueado: true,
      cliente: parseInt(localStorage.getItem("cliente")),
    });
    //this.tiene_navidad();
    //this.tiene_excedentes();
    this.lavado_pendiente();
    this.sorteo_mostrar();
    this.tengo_whatsapp();
    this.hay_stream();
    this.campamento_reservado();
    setTimeout(() => this.abreMenu(), 2000);
  }

  antesrevisaSesionActiva() {
    var cliente = 0;
    var resultado = 0;
    cliente = localStorage.getItem("cliente");
    if (cliente) {
      resultado = parseInt(cliente);
    }
    let estoyActivo = false;
    if (resultado > 0) {
      estoyActivo = true;
    }
    return estoyActivo;
  }

  async revisaSesionActiva() {
    var resultado = true;
    let tk = "";
    try {
      tk = localStorage.getItem("tk");
    } catch (error) { }
    var myHeaders = new Headers();
    myHeaders.append("tk", tk);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/login/activo";
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if (data.respuesta == "E") {
          resultado = false;
        } else {
          if (data.respuesta == "S") {
            console.log("respuesta: " + data.respuesta);
            localStorage.setItem("cliente", parseInt(data.cliente));
            localStorage.setItem("nombre", data.nombre);
            this.setState({
              nombre_socio: data.nombre,
              cliente: parseInt(data.cliente),
            });
          }
        }
      } else {
        resultado = false;
      }
    } catch (error) {
      resultado = false;
    }
    this.setState({
      sesionActiva: resultado,
    });
    return resultado;
  }

  abreMenu(event) {
    let nuevo = "";
    if (this.state.menu_estatus != "oculto") {
      nuevo = "oculto";
    }
    this.setState({
      menu_estatus: nuevo,
    });
  }

  abreDialog(idProceso) {
    if (idProceso == 17) {
      this.setState({
        pregunta_whatsapp: true,
      });
    } else {
      this.setState({
        dialog_estatus: "S",
        dialog_proceso: idProceso,
      });
    }
  }

  cierraDialog() {
    this.setState({
      dialog_estatus: "N",
    });
  }

  nuevaClave(mensaje, cliente) {
    this.setState({
      cambioClave: true,
      cambioClaveMensaje: mensaje,
      cambioClaveCliente: cliente,
    });
  }

  cierreRecuperacion() {
    localStorage.setItem("cliente", 0);
    this.setState({
      sesionActiva: false,
      cambioClave: false,
      cambioClaveMensaje: "",
      cambioClaveCliente: 0,
      piden_recuperacion: false,
      logueado: false,
    });
  }

  cierrePreguntas() {
    localStorage.setItem("cliente", 0);
    localStorage.setItem("tk", "");
    this.setState({
      cliente: 0,
      sesionActiva: false,
      cambioClave: false,
      cambioClaveMensaje: "",
      cambioClaveCliente: 0,
      piden_recuperacion: false,
      piden_preguntas: false,
      logueado: false,
    });
  }
  cierreNavidad() {
    this.setState({
      nav_entrega: false,
      token: "",
    });
    if (!this.state.soyAndroid && !this.state.soyIphone) {
      this.cierraSesion();
    }
  }
  clickNavidad() {
    this.setState({
      nav_entrega: true,
    });
  }

  cierreExcedentes() {
    this.setState({
      pregunta_excedentes: false,
      token: "",
    });
  }

  cierreWhatsapp() {
    this.setState({
      pregunta_whatsapp: false,
    });
  }

  async tiene_navidad() {
    this.setState({
      procesando: true,
    });
    let cliente = parseInt(localStorage.getItem("cliente"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var miData = new URLSearchParams();
    miData.append("cliente", cliente);

    var requestOptions = {
      method: "POST",
      body: miData,
      Headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/login/nav_tiene";
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if (data.respuesta == "S") {
          this.setState({
            muestra_navidad: true,
          });
        } else {
          this.setState({
            muestra_navidad: false,
          });
        }
      } else {
        /*this.setState({
                mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde"
            })*/
      }
    } catch (error) {
      /*this.setState({
            mensaje: "Ha ocurrido un error: " + error
        })*/
    }
    this.setState({
      procesando: false,
    });
  }

  async tiene_excedentes() {
    return;
    this.setState({
      procesando: true,
    });
    let cliente = parseInt(localStorage.getItem("cliente"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var miData = new URLSearchParams();
    miData.append("cliente", cliente);

    var requestOptions = {
      method: "POST",
      body: miData,
      Headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/login/excedentes_tiene";
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const data = await response.json();
        console.log("la respuesta es " + data.monto);
        if (data.respuesta == "S") {
          this.setState({
            excedentes: true,
            excedentes_monto: data.monto,
          });
        } else {
          this.setState({
            excedentes: false,
            excedentes_monto: 0.0,
          });
        }
      } else {
        this.setState({
          mensaje:
            "Ha ocurrido un error de conexión. Por favor intente más tarde",
        });
      }
    } catch (error) {
      this.setState({
        mensaje: "Ha ocurrido un error: " + error,
      });
    }
    this.setState({
      procesando: false,
    });
  }

  async tengo_whatsapp() {
    this.setState({
      procesando: true,
    });
    let cliente = parseInt(localStorage.getItem("cliente"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var miData = new URLSearchParams();
    miData.append("cliente", cliente);

    var requestOptions = {
      method: "POST",
      body: miData,
      Headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/login/whatsapp_tiene";
    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        if (data.respuesta == "S") {
          if (parseInt(data.numero) == 0) {
            this.setState({
              pregunta_whatsapp: true,
            });
          }
          this.setState({
            whatsapp: data.mensaje,
          });
        }
      } else {
        this.setState({
          mensaje:
            "Ha ocurrido un error de conexión. Por favor intente más tarde",
        });
      }
    } catch (error) {
      this.setState({
        mensaje: "Ha ocurrido un error: " + error,
      });
    }
    this.setState({
      procesando: false,
    });
  }

  async hay_stream() {
    this.setState({
      procesando: true,
    });
    let cliente = parseInt(localStorage.getItem("cliente"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var miData = new URLSearchParams();
    miData.append("cliente", cliente);

    var requestOptions = {
      method: "POST",
      body: miData,
      Headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/login/stream_live";
    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.respuesta == "S") {
          if (data.mensaje.length > 0) {
            this.setState({
              link_stream: data.mensaje2,
              texto_stream: data.mensaje,
              viendo_stream: false,
            });
          }
        }
      } else {
        this.setState({
          mensaje:
            "Ha ocurrido un error de conexión. Por favor intente más tarde",
        });
      }
    } catch (error) {
      this.setState({
        mensaje: "Ha ocurrido un error: " + error,
      });
    }
    this.setState({
      procesando: false,
    });
  }

  async ver_stream() {
    this.setState({
      procesando: true,
    });
    let cliente = parseInt(localStorage.getItem("cliente"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var miData = new URLSearchParams();
    miData.append("cliente", cliente);

    var requestOptions = {
      method: "POST",
      body: miData,
      Headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/login/stream_verlo";
    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const data = await response.json();
      } else {
        this.setState({
          mensaje:
            "Ha ocurrido un error de conexión. Por favor intente más tarde",
        });
      }
    } catch (error) {
      this.setState({
        mensaje: "Ha ocurrido un error: " + error,
      });
    }
    this.setState({
      procesando: false,
    });
  }

  async sorteo_mostrar() {
    this.setState({
      procesando: true,
    });
    let cliente = parseInt(localStorage.getItem("cliente"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: "GET",
      Headers: myHeaders,
      redirect: "follow",
    };
    let url =
      "https://api.cooeprouasd.net/api/login/sorteo_mostrar?cliente=" + cliente;
    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        let mostrar = false;
        let participar = 1;
        if (data.respuesta == "S") {
          mostrar = true;
        }
        if (data.respuesta2 == "S") {
          participar = 2;
        }
        this.setState({
          sorteo_renuncia: mostrar,
          sorteo_participa: participar,
        });
      } else {
        this.setState({
          mensaje:
            "Ha ocurrido un error de conexión. Por favor intente más tarde",
        });
      }
    } catch (error) {
      this.setState({
        mensaje: "Ha ocurrido un error: " + error,
      });
    }
    this.setState({
      procesando: false,
    });
  }

  async sorteo_participar() {
    this.setState({
      procesando: true,
    });
    let cliente = parseInt(localStorage.getItem("cliente"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: "GET",
      Headers: myHeaders,
      redirect: "follow",
    };
    let url =
      "https://api.cooeprouasd.net/api/login/sorteo_participar?cliente=" +
      cliente +
      "&participar=" +
      this.state.sorteo_participa;
    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const data = await response.json();
      } else {
        this.setState({
          mensaje:
            "Ha ocurrido un error de conexión. Por favor intente más tarde",
        });
      }
    } catch (error) {
      this.setState({
        mensaje: "Ha ocurrido un error: " + error,
      });
    }
    this.setState({
      procesando: false,
    });
  }

  clickUser() {
    let s = this.state.menu_user;
    if (s == "abierto") {
      s = "";
    } else {
      s = "abierto";
    }
    this.setState({
      menu_user: s,
    });
  }

  clickDividendo() {
    this.setState({
      pregunta_excedentes: true,
    });
  }
  clickCierraSesion() {
    this.cierraSesion();
  }

  sesion_expirada() {
    // this.cierraSesion();
  }

  click_stream() {
    this.setState({
      viendo_stream: true,
    });
    this.ver_stream();
  }

  click_cerrar_stream() {
    this.setState({
      viendo_stream: false,
    });
  }

  click_max_stream() {
    this.setState({
      viendo_stream: false,
    });
    this.abreDialog(19);
  }

  sorteo_cambios(event) {
    const target = event.target;
    const nombre = target.name;

    this.setState({
      sorteo_confirma: true,
      [nombre]: target.value,
    });
  }

  sorteo_cancelar() {
    this.sorteo_mostrar();
    this.setState({
      sorteo_confirma: false,
    });
  }
  async sorteo_confirmar() {
    await this.sorteo_participar();
    this.setState({
      sorteo_confirma: false,
    });
    this.sorteo_mostrar();
  }

  async lavado_pendiente() {
    this.setState({
      procesando: true,
    });
    let params = new URLSearchParams();
    params.append("cliente", localStorage.getItem("cliente"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: "POST",
      body: params,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/lavado/pendiente";
    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        let mostrar = false;
        if (data.respuesta == "S") {
          mostrar = true;
        }
        this.setState({
          pide_lavado: mostrar,
        });
      } else {
        this.setState({
          mensaje:
            "Ha ocurrido un error de conexión. Por favor intente más tarde",
        });
      }
    } catch (error) {
      this.setState({
        mensaje: "Ha ocurrido un error: " + error,
      });
    }
    this.setState({
      procesando: false,
    });
  }

  async campamento_reservado() {
    console.log("vine por aqui");
    this.setState({
      procesando: true,
    });
    let params = new URLSearchParams();
    params.append("cliente", localStorage.getItem("cliente"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: "POST",
      body: params,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/campamento/reservado";
    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const data = await response.json();
        let mostrar = false;
        if (data.respuesta == "S") {
          mostrar = true;
        }
        this.setState({
          campamento_reservado: mostrar,
        });
      } else {
        this.setState({
          mensaje:
            "Ha ocurrido un error de conexión. Por favor intente más tarde",
        });
      }
    } catch (error) {
      this.setState({
        mensaje: "Ha ocurrido un error: " + error,
      });
    }
    this.setState({
      procesando: false,
    });
  }

  close_lavado() {
    this.setState({
      pide_lavado: false,
    });
  }

  cierraCampamento() {
    this.setState({
      abre_campamento: false,
    });
  }

  abreCampamento() {
    this.setState({
      abre_campamento: true,
    });
  }

  render() {
    //console.log("nav entrega: " + this.state.token)
    const txt_monto = parseFloat(this.state.excedentes_monto).format(
      2,
      3,
      ",",
      "."
    );
    const logueado = this.state.logueado;
    const soyAndroid = this.state.soyAndroid;
    //console.log("render: cliente: " + this.state.cliente )
    const piden_recuperacion = this.state.piden_recuperacion;
    const piden_preguntas = this.state.piden_preguntas;
    const nav_entrega = this.state.nav_entrega;
    const muestra_navidad = this.state.muestra_navidad;

    var obj_dialog =
      this.state.dialog_estatus == "S" ? (
        <MiDialog
          visible={this.state.dialog_estatus}
          evtCerrar={this.cierraDialog}
          proceso={this.state.dialog_proceso}
          sesion_expirada={this.sesion_expirada}
          chequeo={this.ChequeaExpiracion}
          link_stream={this.state.link_stream}
        />
      ) : null;
    var obj_anuncio_navidad = muestra_navidad ? (
      <div id="div_anuncios" onClick={this.clickNavidad}>
        <img src={anuncio_navidad} />
        <div className="texto">¿Cómo quiere su ahorro navideño?</div>
      </div>
    ) : null;
    var obj_sorteo = this.state.sorteo_renuncia ? (
      <div id="div_sorteo">
        <div
          style={{
            fontFamily: "tahoma",
            fontSize: "24pt",
            textAlign: "center",
            color: "rgb(23, 151, 10)",
          }}
        >
          Sorteo Navidad 2024
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <div id="div_sorteo_img" style={{ position: "relative" }}>
            <img src={anuncio_sorteo} />
          </div>
          <div className="texto" id="div_sorteo_participa">
            <div>
              <label class="form-label" for="sorteo_participa">
                ¿Desea participar?
              </label>
            </div>
            <div>
              <input
                type="radio"
                className=" "
                checked={this.state.sorteo_participa == 1}
                onChange={this.sorteo_cambios}
                id="sorteo_participa"
                value="1"
                name="sorteo_participa"
              />{" "}
              Sí
            </div>
            <div>
              <input
                type="radio"
                className=" "
                checked={this.state.sorteo_participa == 2}
                onChange={this.sorteo_cambios}
                id="sorteo_participa"
                value="2"
                name="sorteo_participa"
              />{" "}
              No
            </div>
          </div>
        </div>
      </div>
    ) : null;
    var obj_confirma_sorteo = this.state.sorteo_confirma ? (
      <Confirmacion
        texto="¿Está seguro de esta solicitud?"
        evtClick={this.sorteo_confirmar}
        evtCerrar={this.sorteo_cancelar}
      />
    ) : null;
    var obj_excedentes = false ? (
      <div className="divDividendos">
        <div className="titDividendos">
          <div className="ExcTitulo">Su Excedente 2021:</div>
          <div className="ExcMonto">{txt_monto}</div>
        </div>
        <div className="botDividendos">
          <div></div>
          <MiBoton evtClick={this.clickDividendo} texto="¿Cómo lo desea?" />
          <div></div>
        </div>
      </div>
    ) : null;
    var obj_preg_excedentes =
      this.state.pregunta_excedentes > 0 ? (
        <Excedentes cerrar={this.cierreExcedentes} />
      ) : null;

    var obj_anuncio_stream =
      this.state.link_stream.length > 0 && !this.state.viendo_stream ? (
        <div className="div_anuncio_stream">
          <div></div>
          <div>
            <div>
              <img src={logo_live} />
            </div>
            <div className="div_anuncio_stream_blink">
              Estamos transmitiendo en vivo...
            </div>
            <div className="div_anuncio_stream_nombre">
              {this.state.texto_stream}
            </div>

            <div>
              <MiBoton texto="Quiero verlo!" evtClick={this.click_stream} />
            </div>
          </div>
          <div></div>
        </div>
      ) : null;
    var obj_stream =
      this.state.link_stream.length > 0 && this.state.viendo_stream ? (
        <div className="div_stream">
          <div className="div_streamb">
            <iframe
              src={
                "https://www.facebook.com/plugins/video.php?href=" +
                this.state.link_stream
              }
              frameborder="0"
              scrolling="no"
              allowFullScreen="true"
              height="100%"
              width="100%"
            ></iframe>
          </div>

          <div className="div_stream_botones">
            <div></div>
            <MiBoton texto="Cerrar" evtClick={this.click_cerrar_stream} />
            <MiBoton texto="Agrandar" evtClick={this.click_max_stream} />
            <div></div>
          </div>
        </div>
      ) : null;
    var clase_stream =
      this.state.link_stream.length > 0 ||
        muestra_navidad ||
        this.state.sorteo_renuncia ||
        this.state.campamento_infantil ||
        this.state.mostrar_maraton ||
        this.state.muestra_curso
        ? "hay_anuncio"
        : "";

    var obj_res1 =
      this.state.cliente > 0 ? (
        <Resumen tipo_resumen="1" cliente={this.state.cliente} />
      ) : null;
    var obj_res2 =
      this.state.cliente > 0 ? (
        <Resumen tipo_resumen="2" cliente={this.state.cliente} />
      ) : null;
    var obj_res3 =
      this.state.cliente > 0 ? (
        <Resumen tipo_resumen="3" cliente={this.state.cliente} />
      ) : null;
    var obj_res4 =
      this.state.cliente > 0 ? (
        <Resumen tipo_resumen="4" cliente={this.state.cliente} />
      ) : null;
    var obj_chart =
      this.state.cliente > 0 ? (
        <LineChart cliente={this.state.cliente} />
      ) : null;
    var obj_panel1 =
      this.state.cliente > 0 ? (
        <PanelDetalle tipo="1" cliente={this.state.cliente} />
      ) : null;
    var obj_panel2 =
      this.state.cliente > 0 ? (
        <PanelDetalle tipo="2" cliente={this.state.cliente} />
      ) : null;

    var obj_wait = this.state.procesando ? <Wait /> : null;

    var obj_campamento = this.state.campamento_infantil ? (
      <div
        className="div_campamento"
        onClick={() => {
          this.abreCampamento();
        }}
      >
        <div className="camp-tit1">8vo. Campamento Infantil COOEPROUASD</div>
        <div className="camp-tit2">Una Familia Feliz</div>
        <div className="camp-img">
          <div
            className={
              "camp-reservado " +
              (this.state.campamento_reservado ? "reservado" : "")
            }
          >
            <img src={imgReservado} />
          </div>
          <img className="camp-img-img" src={imgCamp} />
        </div>
        <div className="camp-tit3">
          {"<<Para inscribirse, haga CLICK aqui>>"}
        </div>
      </div>
    ) : null;

    var obj_maraton = this.state.mostrar_maraton ?
      <Maraton /> : null;

    var obj_campamnto2 = this.state.abre_campamento ? (
      <Campamento cerrar={this.cierraCampamento} />
    ) : null;

    const obj_cursos = this.state.muestra_curso ? <Cursos curso={this.state.curso_actual} nombre={this.state.curso_nombre} fecha={this.state.curso_fecha} /> : null;

    var obj_app =
      logueado &&
        !piden_recuperacion &&
        !piden_preguntas &&
        !nav_entrega &&
        !this.state.pide_lavado ? (
        <div>
          <input
            type="hidden"
            id="dispositivo"
            value={this.state.soyIphone ? "iphone" : "pc"}
          />
          {obj_preg_excedentes}
          {obj_campamnto2}
          <div
            id="menu_left"
            className={"menu_left " + this.state.menu_estatus}
          >
            <div className="menu_left_cabecera">
              <div>
                <img src={logo} />
              </div>
              <div className="menu-left-close" id="menu_left_cabecera_close_div" onClick={this.abreMenu}>
                <CloseIcon />
              </div>
            </div>
            <MenuLeft
              evtClick={this.abreDialog}
              cierraSesion={this.cierraSesion}
            />
          </div>
          <div className="App">
            <div className="App-header">
              <div id="bt_abre_menu" onClick={this.abreMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div id="App-header-titulo">COOEPROUASD</div>
              <div id="App-header-right">
                <div id="div-saludo">
                  {this.state.saludo}
                  <b>{this.state.nombre_socio}</b>
                </div>
                <div id="div-foto" onClick={this.clickUser}>
                  <img
                    id="img-foto"
                    src={
                      "https://api.cooeprouasd.net/api/login/foto?cliente=" +
                      this.state.cliente +
                      "&chache=a"
                    }
                  />
                </div>
              </div>
            </div>
            <div
              id="div_menu_user"
              onClick={this.clickCierraSesion}
              className={this.state.menu_user}
            >
              <a>Cerrar la Sesión</a>
            </div>
            <Storys />
            <div id="App-body" className={clase_stream}>
              {obj_anuncio_navidad}
              {obj_sorteo}
              {obj_confirma_sorteo}
              {obj_excedentes}
              {obj_anuncio_stream}
              {obj_stream}
              {obj_campamento}
              {obj_maraton}
              {obj_cursos}
              <div id="panel1">
                <div id="res_1">{obj_res1}</div>
                <div id="res_2">{obj_res2}</div>
                <div id="res_3">{obj_res3}</div>
                <div id="res_4">{obj_res4}</div>
              </div>
              <div id="panel2">
                <div id="panel2a">{obj_chart}</div>
                <div id="panel2b">
                  <div className="marcos">{obj_panel1}</div>
                  <div className="marcos">{obj_panel2}</div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      ) : null;

    var obj_login =
      !logueado &&
        !piden_recuperacion &&
        !piden_preguntas &&
        !this.state.pregunta_whatsapp &&
        !soyAndroid &&
        !this.state.soyIphone ? (
        <Login valida={this.abreSesion} cambioClave={this.nuevaClave} />
      ) : null;
    var obj_cambio = this.state.cambioClave ? (
      <CambioClave
        mensaje={this.state.cambioClaveMensaje}
        cliente={this.state.cambioClaveCliente}
        cierre={this.finCambioClave}
      />
    ) : null;
    var obj_recuperacion = this.state.piden_recuperacion ? (
      <RecuperacionClave
        cierre={this.cierreRecuperacion}
        sesion={this.state.recuperacion_sesion}
      />
    ) : null;
    var obj_preguntas = piden_preguntas ? (
      <PreguntasLlenar
        sesion={this.state.preguntas_sesion}
        cierre={this.cierrePreguntas}
      />
    ) : null;
    var obj_whatsapp = this.state.pregunta_whatsapp ? (
      <Whatsapp
        celular={this.state.whatsapp}
        sesion={this.state.preguntas_sesion}
        cierre={this.cierreWhatsapp}
      />
    ) : null;

    var obj_nav = this.state.nav_entrega ? (
      <NavEntrega
        token={this.state.token}
        opcion={this.state.nav_opcion}
        cerrar={this.cierreNavidad}
      />
    ) : null;
    var obj_lavado = this.state.pide_lavado ? (
      <LavadoActivos cerrar={this.close_lavado} />
    ) : null;

    return (
      <div>
        {obj_wait}
        {obj_dialog}
        {obj_app}
        {obj_login}
        {obj_cambio}
        {obj_recuperacion}
        {obj_preguntas}
        {obj_whatsapp}
        {obj_nav}
        {obj_lavado}
      </div>
    );
  }
}

export default App;
