import React from "react";
import "./../css/Resumen.css";

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};


class Resumen extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            balance: 'calculando...',
            texto: 'Total ...',
            clase: ''
        }
    }

    async actBalance(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
console.log("BALANCE: " + this.props.cliente)
        var miData = new URLSearchParams();
        miData.append("tipo", this.props.tipo_resumen);
        miData.append("cliente", this.props.cliente);

        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/Resumen/balance", requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data.respuesta=="S"){
                    this.setState({
                        balance: parseFloat(data.balance).format(2,3,',','.')
                    });
                } else {
                    console.log("mensaje: " + data.mensaje)
                    
                }
            } else {
                console.log("Error de conexión...");
            }
          } catch(error){
            console.log("Error: " + error);
          }
      }

    componentDidMount(){
        if (this.props.tipo_resumen==1){
            this.setState({
                texto: 'Total Ahorros',
                clase: 'ahorros'
            });
        }
        if (this.props.tipo_resumen==2){
            this.setState({
                texto: 'Total Préstamos',
                clase: 'prestamos'
            });
        }
        if (this.props.tipo_resumen==3){
            this.setState({
                texto: 'Total Servicios',
                clase: 'servicios'
            });
        }
        if (this.props.tipo_resumen==4){
            this.setState({
                texto: 'Total Supermercado',
                clase: 'supermercado'
            });
        }

        this.actBalance();


    }

    render(){

        return(
            <div id="cuerpo-resumen">
                <div style={{position: 'relative'}}>
                    <div id="div_logo" className={this.state.clase}></div>
                </div>
                <div id="div_resumen">
                    <div id="txt_monto">{this.state.balance}</div>
                    <div id="texto">{this.state.texto}</div>
                </div>
            </div>
        )
    }
}

export default Resumen;