import React from "react";
import './../css/Login.css';
import MiBoton from './MiBoton';
import Mensaje from "./Mensaje";
import login_logo from './../images/logo.png';
import login from './../images/login.png';
import icon_usr from './../images/user.png';
import icon_pas from './../images/pass.png';
import icon_sad from './../images/sad.png';
import Olvido from "./Olvido";
import NuevosSocios from "./NuevosSocios";

class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            socio: "",
            clave: "",
            validando: false,
            mensaje: "",
            clase_mensaje: "Error",
            olvido_clave: false,
            nuevoSocio: false
        }
        this.evtChange = this.evtChange.bind(this);
        this.valida = this.valida.bind(this);
        this.puedeValidar = this.puedeValidar.bind(this);
        this.evtCerrarMsg = this.evtCerrarMsg.bind(this);
        this.olvidoClave = this.olvidoClave.bind(this);
        this.cierreOlvido = this.cierreOlvido.bind(this);
        this.llamaNuevoSocio = this.llamaNuevoSocio.bind(this);
        this.cierreNuevoSocio = this.cierreNuevoSocio.bind(this)
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        })
    }

    cierreNuevoSocio(){
        this.setState({
            nuevoSocio: false
        })
    }

    async valida(){
        if (this.puedeValidar()){
            this.setState({
                procesando: true
            })
            var socio = this.state.socio;
            var clave = this.state.clave;

            //socio = socio.replaceAll(" ", "");
            if (socio.substr(0,1)=="P" || socio.substr(0,1)=="J"){
                socio = socio.substr(1, socio.length);
            }

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            

            var miData = new URLSearchParams();
            miData.append("socio", socio);
            miData.append("clave", clave);

            var requestOptions = {
                method: 'POST',
                body: miData,
                headers: myHeaders,
                redirect: 'follow'
            };
            let url = "https://api.cooeprouasd.net/api/login/verify";
            try {
                const response = await fetch(url, requestOptions);
                if (response.ok){
                    const data = await response.json();
                    if (data.respuesta=="S"){
                        localStorage.setItem("cliente", data.cliente);
                        localStorage.setItem("socio", data.socio);
                        localStorage.setItem("nombre", data.nombre);
                        localStorage.setItem("apellido", data.apellido);
                        localStorage.setItem("tk", data.mensaje);
                        this.props.valida();
                    } else {
                        if (data.respuesta=="C"){
                            this.setState({
                                socio: "",
                                clave: ""
                            })
                            this.props.cambioClave("Por motivos de seguridad, por favor introduzca una nueva contraseña", data.cliente);
                        } else {
                            this.setState({
                                mensaje: data.mensaje
                            })
                        }
                        
                    }
                
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error: " + error
            })
          }
          this.setState({
              procesando: false
          })
        }
    }

    puedeValidar(){
        var puede = true;
        var mensaje = "";
        const socio = this.state.socio;
        const clave = this.state.clave;
        if (socio.length==0){
            puede = false;
            mensaje = "Debe digitar su código de empleado";
        }
        if (clave.length==0 && puede){
            puede = false;
            mensaje = "Debe digitar su contraseña";
        }
        if (!puede){
            this.setState({
                mensaje: mensaje
            })
        }
        return puede;
    }

    evtCerrarMsg(){
        this.setState({
            mensaje: ""
        })
    }

    olvidoClave(){
        this.setState({
            olvido_clave: true
        })
    }
    cierreOlvido(){
        this.setState({
            olvido_clave: false
        })
    }
    llamaNuevoSocio(){
        this.setState({
            nuevoSocio: true
        })
    }

    render(){
        const obj_msg = (this.state.mensaje.length>0?<Mensaje clase={this.state.clase_mensaje} texto={this.state.mensaje} evtCerrar={this.evtCerrarMsg}/>: null);
        const obj_olvido = (this.state.olvido_clave?<Olvido procesoCierre={this.cierreOlvido} />: null);
        const obj_nuevo = (this.state.nuevoSocio? <NuevosSocios cierre={this.cierreNuevoSocio} />: null);
        return(
            <div className="Fondo-Login">
                {obj_olvido}
                {obj_nuevo}
                <div className="Div-Login">
                    <div id="bt_nuevo" onClick={this.llamaNuevoSocio}>¿Aún no eres socio?<br/>Inscríbete aquí!</div>
                    <div id="div_login_icon"><img src={login} /></div>
                    <div id="div_login_logo">
                        <div></div>
                        <div><img id="logo_login" src={login_logo} /></div>
                        <div></div>
                    </div>
                    <div id="div_login_datos">
                        <div className="misRows" style={{gridTemplateColumns: '40px 1fr', marginBottom: '10px'}}>
                            <div style={{alignSelf: 'center', justifySelf: 'right'}}>
                                <img src={icon_usr} style={{width: '20px', height: 'auto', opacity: '.9'}} />
                            </div>
                            <div>
                                <input type="text" className="form-control form-control-sm " autocomplete="off" placeholder="Código" name="socio" value={this.state.socio} onChange={this.evtChange} style={{maxWidth: '200px'}} />
                            </div>
                        </div>
                        <div className="misRows" style={{gridTemplateColumns: '40px 1fr', marginBottom: '0px'}}>
                            <div style={{alignSelf: 'center', justifySelf: 'right'}}>
                                <img src={icon_pas} style={{width: '20px', height: 'auto', opacity: '.9'}} />
                            </div>
                            <div>
                                <input type="password" className="form-control form-control-sm" autocomplete="off" placeholder="Contraseña" name="clave" value={this.state.clave} onChange={this.evtChange} style={{maxWidth: '200px'}} />
                            </div>
                        </div>
                        <div className="misRows" style={{gridTemplateColumns: '40px 200px 1fr'}}>
                            <div></div>
                            <div>
                                <MiBoton enEspera={this.state.procesando} evtClick={this.valida} miNombre="btValida" texto="Entrar" />
                            </div>
                        </div>
                        <div className="misRows" id="login_mensaje_f" style={{gridTemplateColumns: '40px 1fr'}}>
                            <div></div>
                            <div id="login_mensaje"></div>
                        </div>
                        <div id="div_login_olvido">
                            <img src={icon_sad} />
                            <div id="a_login_olvido"><a href="#" onClick={this.olvidoClave}>Olvidé mi contraseña</a></div>
                        </div>
                    </div>
                </div>
                {obj_msg}
            </div>
        )
    }
}

export default Login;