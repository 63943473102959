import React from 'react';
import '../css/AplicarVolante.css';
import MiBoton from './MiBoton';
import MiEdit from './MiEdit';
import Wait from './Wait.jsx';
import MiSelect from './MiSelect';
import Confirmacion from './Confirmacion';
import Mensaje from './Mensaje';
import nophoto from '../images/nophoto.png';
import RowTabla from "./RowTabla";
import TomaFoto from "./TomaFoto";

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
var data_imagen;

class AplicarVolante extends React.Component {
    
    constructor(props ){
        super(props);
        this.state = {
            documento_monto: "0.00",
            documento_referencia: "",
            movimiento: 0,
            exito_oculto: "oculto",
            error_oculto: "oculto",
            a_propositos: [],
            proposito: 0,
            datos_ocultos: "oculto",
            error_mensaje: "",
            procesando: false,
            texto_mensaje: "",
            clase_mensaje: "Error",
            cierre_mensaje: null,
            datos: [],
            total_pagos: "0.00",
            toma_foto: false,
            pide_confirmacion: false,
            encontrado: ""
            
        }
        this.evtChange = this.evtChange.bind(this);
        this.actPropositos = this.actPropositos.bind(this);
        this.puedeBuscar = this.puedeBuscar.bind(this);
        this.clickBuscar = this.clickBuscar.bind(this);
        this.buscaDatos = this.buscaDatos.bind(this);
        this.evtCerrarMensaje = this.evtCerrarMensaje.bind(this);
        this.muestraFoto = this.muestraFoto.bind(this);
        this.calcTotalValores = this.calcTotalValores.bind(this);
        this.clickGrabar = this.clickGrabar.bind(this);
        this.puedeGrabar = this.puedeGrabar.bind(this);
        this.grabarAplicacion = this.grabarAplicacion.bind(this);
        this.evtClickConfirmacion = this.evtClickConfirmacion.bind(this);
        this.evtCerrarConf = this.evtCerrarConf.bind(this);
        this.evtChangeCampos = this.evtChangeCampos.bind(this)

        this.props.titulo("Aplicar mi Depósito");
        this.actPropositos();
    }

    evtChangeCampos(nombre, valor){
        console.log("llego aqui!!!!!" + nombre + ", " + valor)
        console.log(this.state.a_propositos)
        let tam = nombre.length;
        let id = parseInt(nombre.substr(3, tam));
        //this.state.a_propositos[id-1].monto = parseFloat(valor);
       
        this.setState({
            a_propositos: this.state.a_propositos.map((row, i)=>{
                if (row.id==(id)){
                    let larow = row;
                    larow.monto  = parseFloat(valor)
                    return larow;
                }
                return row;
            })
        })
        console.log(this.state.a_propositos)
    }

    muestraFoto(){
        var reader = new FileReader();
            var file = document.getElementById("get_file");
            reader.onload = function (e) {
                document.getElementById("foto_documento").src = e.target.result;
            }
            reader.readAsDataURL(file.files[0]);


            var fotos = file.files;
            var archivo = fotos[0];
            var _URL = window.URL || window.webkitURL;
            var img_original = new Image();
            var w_actual = 0;
            var h_actual = 0;
            img_original.src = _URL.createObjectURL(archivo);
            img_original.onload = function () {
                w_actual = this.width;
                h_actual = this.height;
                var reader2 = new FileReader();
                reader2.readAsDataURL(archivo);
                reader2.onload = event => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        var porc = 256 / w_actual;
                        var w = 256;
                        var h = h_actual * porc;
                        //alert("w:" + w + ", h:" + h);
                        const elem = document.createElement("canvas");
                        elem.width = w;
                        elem.height = h;
                        const ctx = elem.getContext("2d");
                        ctx.drawImage(img, 0, 0, w, h);
                        data_imagen = ctx.canvas.toDataURL();
                        
                    }
                }
            }
    }

    evtCerrarMensaje(){
        this.setState({
            texto_mensaje: ""
        })
        if (this.state.cierre_mensaje !=null){
            this.state.cierre_mensaje();
        }
        
    }
    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        })
    }

    async actPropositos(){
        var a_pr = [];
        var resp_datos = [];
        let tk = "";
        try{
            tk = localStorage.getItem("tk");
        } catch(error){

        }
        var myHeaders = new Headers();
        myHeaders.append("tk", tk);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));

        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/depositos/propositos3";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data.respuesta=="S"){
                    var a;
                    for(a=0;a<data.lista.length; a++){
                        let item = {id: data.lista[a].id.toString(), tipo: data.lista[a].tipo, nombre: data.lista[a].texto, prestamo: data.lista[a].prestamo, balance: data.lista[a].balance, monto: 0.00}
                        let item2 = {};
                        item2.id = data.lista[a].id;
                        if (data.lista[a].tipo==3 || data.lista[a].tipo==4 || data.lista[a].tipo==5){
                            item2.columnas = [data.lista[a].texto, "[valo2]apl"];
                        } else {
                            item2.columnas = [data.lista[a].texto, "[valor]apl"];
                        }
                        item2.montofijo = data.lista[a].balance;
                        item2.marca = false;
                        item2.valor = 0.00;
                        item2.presenta = "";

                        a_pr.push(item);
                        resp_datos.push(item2)
                    }
                    
                    this.setState({
                        a_propositos: a_pr,
                        datos: resp_datos 
                    });
                } else {
                    //alert("mensaje: " + data.mensaje)
                    
                }
            } else {
                //alert("Error de conexión...");
            }
          } catch(error){
            //alert("Error: " + error);
          }
    }

    async buscaDatos(){
        this.setState({
            movimiento: 0,
            procesando: true
        })
        let tk = "";
        try{
            tk = localStorage.getItem("tk");
        } catch(error){

        }
        var myHeaders = new Headers();
        myHeaders.append("tk", tk);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        miData.append("referencia", this.state.documento_referencia);
        miData.append("monto", this.state.documento_monto);
        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/depositos/buscar";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data.respuesta=="S"){
                    this.setState({
                        movimiento: data.numero,
                        exito_oculto: "",
                        error_oculto: "oculto",
                        datos_ocultos: "",
                        encontrado: "encontrado"
                    });
                } else {
                    this.setState({
                        movimiento: 0,
                        exito_oculto: "oculto",
                        error_oculto: "",
                        datos_ocultos: "oculto",
                        texto_mensaje: data.mensaje,
                        clase_mensaje: "Error"
                    });
                }
            } else {
                this.setState(
                    {
                        texto_mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                        clase_mensaje: "error"
                    }
                )
            }
          } catch(error){
            this.setState(
                {
                    texto_mensaje: error,
                    clase_mensaje: "error"
                }
            )
          }

          this.setState({
            procesando: false
        })
    }

    clickBuscar(){
        if (this.puedeBuscar()){
            this.buscaDatos();
        }
    }

    puedeBuscar(){
        let resultado = true;
        let mensaje = "";
        if (this.state.documento_referencia.length==0){
            mensaje = "Debe digitar la referencia de su volante de depósito";
            resultado = false;
        }
        if (parseFloat(this.state.documento_monto)==0){
            mensaje = "Debe digitar el monto del depósito";
            resultado = false;
        }
        if (mensaje.length>0){
            this.setState({
                texto_mensaje: mensaje,
                clase_mensaje: "info",
                
            })
        }
        return resultado;
    }

    calcTotalValores(valor){
        //console.log("el total es", valor)
        this.setState({
            total_pagos: valor
        })
    }

    evtClickConfirmacion(event){
        if (this.puedeGrabar()){
            this.grabarAplicacion();
        }
    }

    evtCerrarConf(){
        this.setState({
            pide_confirmacion: false 
        })
    }

    clickGrabar(){
        if (this.puedeGrabar()){
            this.setState({
                pide_confirmacion: true
            })
        }
    }

    puedeGrabar(){
        let resultado = true;
        let sTotal = this.state.total_pagos.replace(",", "").replace(",", "").replace(",", "");
        let total = parseFloat(sTotal);
        let totalDocumento = parseFloat(this.state.documento_monto);
        if (total-totalDocumento>1 || total-totalDocumento<-1){
            resultado = false;
            this.setState({
                texto_mensaje: "Desglose aplicación no cuadra con el monto del depósito",
                error_mensaje: "error"
            });
        }

        if (resultado){
            for(var i=0;i<this.state.a_propositos.length;i++){
                if(this.state.a_propositos[i].tipo==6){
                    if (this.state.a_propositos[i].monto>this.state.a_propositos[i].balance){
                        resultado = false;
                        this.setState(
                            {
                                texto_mensaje: "Aplicación mayor al balance adeudado",
                                error_mensaje: "error"
                            }
                        )
                        break;
                    }
                }
                
            }
        }
        if (resultado){
            if (this.state.movimiento==0){
                resultado = false;
                this.setState(
                    {
                        texto_mensaje: "Debe introducir los datos del volante",
                        error_mensaje: "error"
                    }
                )
            }
            
        }

        return resultado;
    }

    async grabarAplicacion(){
        this.setState(
            {
                procesando: true
            }
        )
        let json = [];
        for(var i = 0; i<this.state.a_propositos.length;i++){
            let item = {};
            item.tipo = this.state.a_propositos[i].tipo;
            item.prestamo = this.state.a_propositos[i].prestamo;
            item.monto = this.state.a_propositos[i].monto;
            json.push(item);
        }
        let json_aplicacion = JSON.stringify(json);
        console.log(json_aplicacion)

        let tk = "";
        try{
            tk = localStorage.getItem("tk");
        } catch(error){

        }

        var myHeaders = new Headers();
        myHeaders.append("tk", tk);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        miData.append("movimiento", this.state.movimiento);
        miData.append("aplicacion", json_aplicacion);


        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/depositos/aplicar";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data.respuesta=="S"){

                    this.setState(
                        {
                            pide_confirmacion: false,
                            texto_mensaje: "Su depósito fue aplicado exitosamente!",
                            clase_mensaje: "info",
                            cierre_mensaje: this.props.procesoCierre
                        }
                    );
                    
                   
                } else {
                    this.setState(
                        {
                            pide_confirmacion: false,
                            texto_mensaje: data.mensaje,
                            clase_mensaje: "error"
                        }
                    )
                }
            } else {
                this.setState(
                    {
                        texto_mensaje: "Ha ocurrido un error de conexión, por favor intente más tarde",
                        clase_mensaje: "error",
                        pide_confirmacion: false
                    }
                )
            }
          } catch(error){
            this.setState(
                {
                    texto_mensaje: error,
                    clase_mensaje: "error",
                    pide_confirmacion: false
                }
            )
          }

          this.setState(
            {
                procesando: false
            }
          )

    }

    render(){
        const obj_wait = (this.state.procesando?<Wait />: null);
        const obj_mensaje = (this.state.texto_mensaje.length>0 ? <Mensaje clase={this.state.clase_mensaje} texto={this.state.texto_mensaje} evtCerrar={this.evtCerrarMensaje} />:null);
        const obj_foto = (this.state.toma_foto?<TomaFoto />: null);
        const obj_confirmacion = (this.state.pide_confirmacion? <Confirmacion enEspera={this.state.procesando} texto="¿Desea aplicar su depósito?" evtCerrar={this.evtCerrarConf} evtClick={this.evtClickConfirmacion} />:null);
        
        var a_titulos = [
            {texto: 'Concepto', ancho: '1fr'},
            {texto: 'Monto', ancho: '150px'}
            ];
        var a_justifica = ['I', 'C'];
        

         var datos = {
            titulo: "",
            titulos: a_titulos,
            justifica: a_justifica,
            datos: this.state.datos
        }; 


        return(
            <div className={'Vol-Cuerpo ' + this.state.encontrado}>
                {obj_wait}
                {obj_mensaje}
                {obj_foto}
                {obj_confirmacion}
                <div className={'Vol-Header ' + this.state.encontrado}>
                    <div className={'a_lab1 ' + this.state.encontrado}>
                        <label className='col-form-label Vol-Filtro-Labels'>Número del Volante:</label>
                    </div>
                    <div className={'a_lab2 ' + this.state.encontrado}>
                        <label className='col-form-label Vol-Filtro-Labels'>Monto del Depósito:</label>
                    </div>
                    <div className={'a_inp1 ' + this.state.encontrado}>
                        <input className='form-control' name="documento_referencia" autoComplete='off' value={this.state.documento_referencia} onChange={this.evtChange}/>
                    </div>
                    <div className={'a_inp2 ' + this.state.encontrado}>
                        <div></div>
                        <div><MiEdit  miNombre="documento_monto" valor={this.state.documento_monto} evtChange={this.evtChange} /></div>
                        <div></div>
                    </div>
                    <div className={'a_btbuscar ' + this.state.encontrado}>
                        <MiBoton texto="Buscar" miNombre="btBuscar" evtClick={this.clickBuscar} />
                    </div>
                    <div className='a_btgrabar'>
                    </div>
                    <div className='a_mens'>
                        <div className='divError'>
                            <div></div>
                            <div className={'Vol-Msg-Error ' + this.state.error_oculto}>{this.state.error_mensaje}</div>
                            <div></div>
                        </div>
                    
                        <div className={'Vol-Msg-Exito ' + this.state.exito_oculto}>Felicidades!!!, hemos encontrado su volante.<br /><b>¿Qué desea hacer con su depósito?</b></div>
                    </div>
                    <div className='a_lin'></div>
                </div>
                
                <div className='Vol-Datos'>
                    <div className={this.state.datos_ocultos}>
                        <RowTabla data={datos} evtClick={this.evtClick} total_valores={this.calcTotalValores} evtChangeCampos={this.evtChangeCampos} />
                    </div>

                </div>
                <div className='Vol-Footer'>
                    <div></div>
                    <div className='Vol-Footer-Total'>{this.state.total_pagos}</div>
                </div>
                <div className='Vol-Footer-Grabar'>
                    <div></div>
                    <div><MiBoton texto="Aplicar mi Depósito!" miNombre="btGrabar" evtClick={this.clickGrabar} /></div>
                    <div></div>
                </div>
            </div>
        )
    }
}

export default AplicarVolante;