import React from "react";
import './../css/CasasComerciales.css';
import MiSelect from './../components/MiSelect.jsx';
import RowTabla from './RowTabla.jsx';

class CasasComerciales extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            tipo: '0',
            casas: []
        }
        this.evtChange = this.evtChange.bind(this)
        this.actCasas = this.actCasas.bind(this)

        this.props.titulo("Listado de Casas Comerciales");
        this.actCasas("0");

    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        });
        this.actCasas(target.value)
    }

    prueba(){
        alert(1)
    }

    async actCasas(tipo){
        var a_casas = [];
        let tk = "";
        try{
            tk = localStorage.getItem("tk");
        } catch(error){

        }
        var myHeaders = new Headers();
        myHeaders.append("tk", tk);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("tipo", tipo);

        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/ordenes/casas2";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data[0].respuesta=="S"){
                    var a;
                    for(a=0;a<data.length; a++){
                        let item = {};
                        item.id = data[a].codigo;
                        item.columnas = [data[a].nombre, data[a].ciudad, data[a].telefono, "[links]https://facebook.com/" + data[a].facebook + "," + data[a].facebook, "[links]https://instagram.com/" + data[a].instagram + "," + data[a].instagram];
                        a_casas.push(item);
                    }
                    
                    this.setState({
                        casas: a_casas
                    });
                } else {
                    //alert("mensaje: " + data.mensaje)
                    
                }
            } else {
                //alert("Error de conexión...");
            }
          } catch(error){
            //alert("Error: " + error);
          }
    }

    render(){
        const datos_tipos = [

                                    {codigo:"08", nombre: "Electrodomésticos", padre: "0"},
                                    {codigo:"09", nombre: "Computadoras", padre: "0"},                                    
                                    {codigo:"10", nombre: "Agencias de Viaje", padre: "0"},                                    
                                    {codigo:"11", nombre: "Inversores", padre: "0"},
                                    {codigo:"12", nombre: "Neumáticos o Repuestos", padre: "0"},
                                    {codigo:"13", nombre: "Farmacias", padre: "0"},
                                    {codigo:"14", nombre: "Opticas", padre: "0"},
                                    {codigo:"15", nombre: "Tiendas", padre: "0"},
                                    {codigo:"19", nombre: "Odontología", padre: "0"}
        ];
        var a_titulos = [
            {texto: 'Nombre', ancho: '1fr'},
            {texto: 'Ciudad', ancho: '1fr'},
            {texto: 'Teléfono', ancho: '1fr'},
            {texto: 'Facebook', ancho: '1fr'},
            {texto: 'Instagram', ancho: '1fr'}
            ];
        var a_justifica = ['I', 'I', 'C', 'I', 'I'];
        
         var datos = {
            titulo: "",
            titulos: a_titulos,
            justifica: a_justifica,
            datos: this.state.casas
        }; 
        return(
            <div className="Div-Casas">
                <div className="RowsCC" >
                    <div><label className=' EtiqCC'>Tipo de Casa Comercial:</label></div>
                    <MiSelect filas={datos_tipos} padre={0} miNombre="tipo" evtCambios={this.evtChange} valor={this.state.tipo} />
                </div>
                <div className="Div-Tabla">
                    <RowTabla data={datos}/>
                </div>
            </div>
        )
    }
}

export default CasasComerciales;