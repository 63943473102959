import React from "react";
import "./../css/DetalleDescuentos.css";
import RowTabla from './RowTabla';
import Wait from './Wait';
import MiSelect from './MiSelect';
import MiBoton from './MiBoton';

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
class DetalleDescuentos extends React.Component{
    constructor(props){
        super(props);
        let hoy = new Date();
        var mm = hoy.getMonth() + 1;
        var yyyy = hoy.getFullYear();
        this.state = {
            datos: [],
            mes: mm,
            ano: yyyy,
            buscandoDatos: false
        }
        this.actDatos = this.actDatos.bind(this);
        this.props.titulo("Detalle de Descuentos");
        this.evtChange = this.evtChange.bind(this);
        this.evtClickBuscar = this.evtClickBuscar.bind(this);
        this.actDatos();
    }

    async actDatos(){
        this.setState({
            buscandoDatos: true
        })
        let tk = "";
        try{
            tk = localStorage.getItem("tk");
        } catch(error){

        }
        var myHeaders = new Headers();
        myHeaders.append("tk", tk);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        miData.append("mes", this.state.mes);
        miData.append("ano", this.state.ano);
        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/Estados/descuentos", requestOptions);
            if (response.ok){
                const data = await response.json();
                var a_datos = [];
                if (data[0].respuesta=="S"){
                    var a;
                    for(a=0;a<data.length;a++){
                        let item = {};
                        item.id = a;
                        item.columnas = [data[a].concepto,  parseFloat(data[a].solicitado).format(2,3,',','.'), parseFloat(data[a].aprobado).format(2,3,',','.')]
                        a_datos.push(item);
                    }

                    this.setState({
                        datos: a_datos
                    });
                } else {
                    //alert("mensaje: " + data[0].mensaje)
                    
                }
            } else {
                alert("Error de conexión...");
            }
          } catch(error){
            alert("Error: " + error);
          }
          this.setState({
              buscandoDatos: false
          })
    }



    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        })
    }

    evtClickBuscar(event){
        this.actDatos();
    }



    render(){
        
        const obj_wait = (this.state.buscandoDatos?<Wait />: null);
        var datos = {
            titulo: '',
            titulos: [
                {texto: 'Concepto', ancho: '1fr'},
                {texto: 'Solicitado', ancho: '1fr'},
                {texto: 'Aprobado', ancho: '1fr'}
                ],
            justifica:  ['L', 'D', 'D'],
            datos: this.state.datos
        }; 
        var a_foot = ["Total:", "sum", "sum"]
        const datos_mes = [{codigo:1, nombre: "Enero", padre:0},
                            {codigo:2, nombre: "Febrero", padre:0},
                            {codigo:3, nombre: "Marzo", padre:0}  ,  
                            {codigo:4, nombre: "Abril", padre:0},
                            {codigo:5, nombre: "Mayo", padre:0},
                            {codigo:6, nombre: "Junio", padre:0},
                            {codigo:7, nombre: "Julio", padre:0},
                            {codigo:8, nombre: "Agosto", padre:0},
                            {codigo:9, nombre: "Septiembre", padre:0},
                            {codigo:10, nombre: "Octubre", padre:0},
                            {codigo:11, nombre: "Noviembre", padre:0},
                            {codigo:12, nombre: "Diciembre", padre:0}
                        ]
        
        return(
            <div className="Div-Estado">
                {obj_wait}
                <div className="RowFiltro2">
                    <div>
                        <div className="RowFiltro">
                            <div><label className=' EtiqOrd'>Mes:</label></div>
                            <MiSelect filas={datos_mes} padre={0} miNombre="mes" evtCambios={this.evtChange} valor={this.state.mes} />
                        </div>
                        <div className="RowFiltro">
                            <div><label className=' EtiqOrd'>Año:</label></div>
                            <div><input className="form-control" type="number" name="ano" onChange={this.evtChange} value={this.state.ano} style={{maxWidth: '200px'}} /></div>
                        </div>
                    </div>
                    <div>
                        <MiBoton texto="Buscar" miNombre="btBuscar" evtClick={this.evtClickBuscar} />
                    </div>
                </div>
                <div><RowTabla footer={a_foot}  data = {datos}/></div>
            </div>
        )
    }

}

export default DetalleDescuentos;