import React from "react";
import './../css/Excedentes.css';
import cerdito from './../images/cerdito.png';
import MiBoton from "./MiBoton";
import Mensaje from "./Mensaje";
import Wait from "./Wait";

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

class Excedentes extends React.Component {
    constructor(props){
        super(props);
        this.state={
            opcion: this.props.opcion,
            monto: 0.00,
            procesando: false,
            mensaje: "",
            clase_mensaje: "Error"
        }
        this.evtChange = this.evtChange.bind(this);
        this.inicio = this.inicio.bind(this);
        this.cerrarMensaje = this.cerrarMensaje.bind(this);
        this.grabar = this.grabar.bind(this);
        this.evtClick = this.evtClick.bind(this)
        this.inicio();
        
    }

    evtChange(event){
        const target = event.target;
        this.setState({
            opcion: target.value
        })

    }

    cerrarMensaje(){
        this.props.cerrar();
    }

    async inicio(){
        this.setState({
            procesando: true
        })
        let cliente = 0;
        let x = localStorage.getItem("cliente");
        if (x==null){
            cliente = 0
        } else {
            cliente = parseInt(x)
        }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var miData = new URLSearchParams();
            miData.append("token", this.props.token);
            miData.append("cliente", cliente);

            var requestOptions = {
                method: 'POST',
                body: miData,
                Headers: myHeaders,
                redirect: 'follow'
            };
            let url = "https://api.cooeprouasd.net/api/login/excedentes_inicio";
            try {
                const response = await fetch(url, requestOptions);
                if (response.ok){
                    const data = await response.json();
                    if (data.respuesta=="S"){
                        this.setState({
                            opcion: parseInt(data.numero),
                            monto: data.monto
                        })

                    } else {
                        this.setState({
                            mensaje: data.mensaje,
                            clase_mensaje: "Error"
                        })
                        
                    }
                
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error: " + error
            })
          }
          this.setState({
              procesando: false
          })
        
    }

    evtClick(){
        this.grabar();
    }

    async grabar(){
        this.setState({
            procesando: true
        })
        let x = localStorage.getItem("cliente")
        let cliente = 0;
        if (x==null){
            cliente = 0;
        } else {
            cliente = parseInt(x)
        }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var miData = new URLSearchParams();
            miData.append("token", this.props.token);
            miData.append("cliente", cliente);
            miData.append("opcion", this.state.opcion);

            var requestOptions = {
                method: 'POST',
                body: miData,
                Headers: myHeaders,
                redirect: 'follow'
            };
            let url = "https://api.cooeprouasd.net/api/login/excedentes_grabar";
            try {
                const response = await fetch(url, requestOptions);
                if (response.ok){
                    const data = await response.json();
                    if (data.respuesta=="S"){
                        this.setState({
                            mensaje: "Su solicitud ha sido procesada con éxito",
                            clase_mensaje: "Info"
                        })

                    } else {
                        this.setState({
                            mensaje: data.mensaje,
                            clase_mensaje: "Error"
                        })
                        
                    }
                
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error: " + error
            })
          }
          this.setState({
              procesando: false
          })
        
    }

    render(){
        var txt_monto = parseFloat(this.state.monto).format(2,3,',','.')
        var obj_mensaje = (this.state.mensaje.length>0?<Mensaje evtCerrar={this.cerrarMensaje} texto={this.state.mensaje} clase={this.state.clase_mensaje} />:null);
        var obj_wait = (this.state.procesando?<Wait />:null);
        return (
            <div className="FondoNav">
                <div className="DivExc">
                    {obj_mensaje}
                    {obj_wait}
                    <div className="rowsNav2">
                        <div className="ExcTitulo">Su Excedente 2021:</div>
                        <div className="ExcMonto">{txt_monto}</div>
                    </div>
                    <div className="quiereExcedente">¿Cómo lo desea?</div>
                    <div className="rowsNav">
                        <div><input type='radio' checked={(this.state.opcion==2?true:false)} onChange={this.evtChange} value="2" name="opcion"/></div>
                        <div>1.- Transferencia directa a su cuenta del Banco de Reservas</div>
                    </div>
                    <div className="rowsNav">
                        <div><input type='radio' checked={(this.state.opcion==3?true:false)} onChange={this.evtChange} value="3" name="opcion"/></div>
                        <div>2.- Depositarlo en su Ahorro Normal</div>
                    </div>
                    <div className="rowsNav">
                        <div><input type='radio' checked={(this.state.opcion==1?true:false)} onChange={this.evtChange} value="1" name="opcion"/></div>
                        <div>3.- Háganme un cheque, por el cual acepto cubrir el costo de RD$300.00 Trecientos Pesos con 00/10) deducible de mi Excedente 2021</div>
                    </div>
                    <div className="DivBot">
                        <div></div>
                        <MiBoton texto="Ok" miNombre="btNavGrabar" evtClick={this.evtClick} />
                        <div></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Excedentes;