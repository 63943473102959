import React from "react";
import './../css/Prestamos.css';
import MiEdit from "./MiEdit";
import MiBoton from './MiBoton.jsx'
import Mensaje from './Mensaje.jsx'
import Wait from './Wait.jsx'
import Confirmacion from './Confirmacion.jsx';
import Pregunta from "./Pregunta";

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
class Prestamos extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            valor: "0.00",
            cuotas: 0,
            tipo: "",
            codtipo: "",
            montoCuota: "",
            montoExpreso : 0.00,
            montoNormal : 0.00,
            montoEspecial : 0.00,
            tasaExpreso : 0.00,
            tasaNormal : 0.00,
            tasaEspecial : 0.00,
            balancePrestamoActual : 0.00,
            escala : [],
            buscandoDatos: true,
            mensaje: "",
            mensaje_clase: "Error",
            mensaje_proceso: null,
            mensaje_error: "",
            disabled: "",
            pide_confirmacion: false,
            pregunta_seguridad: true
        }
        this.inicio = this.inicio.bind(this);
        this.evtChange = this.evtChange.bind(this);
        this.cierraMensaje=this.cierraMensaje.bind(this)
        this.verifica = this.verifica.bind(this);
        this.evtClick = this.evtClick.bind(this);
        this.cancelaConfirmacion = this.cancelaConfirmacion.bind(this);
        this.confirmaOk = this.confirmaOk.bind(this);
        this.solicita = this.solicita.bind(this);
        this.procesoCierre = this.procesoCierre.bind(this);
        this.respuesta_correcta = this.respuesta_correcta.bind(this);

        this.props.titulo("Préstamos Expresos, Normales y Especiales");
        this.inicio();
    }

    async inicio(){
        this.setState({
            buscandoDatos: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/prestamos/inicio", requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data[0].respuesta=="S"){
                    let datos = []
                    var a;
                    for(a=0;a<data.length;a++){
                        var elem = {};
                        elem["tipo"] = data[a].tipo;
                        elem["desde"] = parseFloat(data[a].desde);
                        elem["hasta"] = parseFloat(data[a].hasta);
                        elem["cuotas"] = parseInt(data[a].cuotas);
                        datos.push(elem);
                    }
                    this.setState({
                        montoExpreso : parseFloat(data[0].montoexpreso),
                        montoNormal : parseFloat(data[0].montonormal),
                        montoEspecial : parseFloat(data[0].montoespecial),
                        tasaExpreso : parseFloat(data[0].tasaexpreso),
                        tasaNormal : parseFloat(data[0].tasanormal),
                        tasaEspecial : parseFloat(data[0].tasaespecial),
                        balancePrestamoActual : parseFloat(data[0].balanceactual),
                        escala: datos
                        
                    })
                    //alert("expreso: " + data[0].montoexpreso + ", normal: " + data[0].montonormal + ", especial: " + data[0].montoespecial)
                } else {
                    this.setState({
                        mensaje: data[0].mensaje,
                        clase_mensaje: "Error",
                        mensaje_proceso: this.props.procesoCierre
                    })
                    
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    clase_mensaje: "Error"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Error: " + error,
                clase_mensaje: "Error"
            })
          }
          this.setState({
              buscandoDatos: false
          })
    }

    maxCuotaNormal(monto, tipo) {
        var resultado = 0;
        let aExpresos = this.state.escala;
        var cc;
        for (cc = 0; cc < aExpresos.length; cc++) {
            if (parseInt(tipo) == parseInt(aExpresos[cc].tipo)) {
                if (monto >= aExpresos[cc].desde && monto <= aExpresos[cc].hasta) {
                    resultado = aExpresos[cc].cuotas;
                    break;
                }
            }
        }
        return resultado;

    }

    calcCuotaNormal(cuotas, monto, tasa) {
        let resultado = "";
        var montoCuota = 0.00;
        if (monto > 0 && cuotas > 0) {
            montoCuota = (((monto * (cuotas + 1)) / tasa) / cuotas) + (monto / cuotas);
            resultado = "Su cuota será de RD$" + montoCuota.format(2, 3, ',', '.');
        } else {
            resultado = "Debe digitar monto y cuotas.";
        }
        return resultado;
    }

    verifica(arreglaCuotas, di_mensaje){
        let resultado = false;
        let cuotas = parseInt(this.state.cuotas);
        let monto = parseFloat(this.state.valor);
        let montoCuota = "";
        let mensaje = "";
        let tipo = "";
        let codtipo = "00";
        let balanceActual = this.state.balancePrestamoActual;
        let montoExpreso = this.state.montoExpreso;
        let montoNormal = this.state.montoNormal;
        let montoEspecial = this.state.montoEspecial;
        let tasa = 0.00;
        if (arreglaCuotas){
            cuotas = 0;
        }
        //alert("balance: " + balanceActual)
        if (monto<balanceActual){
            mensaje = "Error. Monto menor al balance actual";
        } else {
            if (monto<10000){
                mensaje = "Error. Monto mínimo para un préstamo es RD$10,000.00"
            } else {
                if (monto <= montoExpreso){
                    tipo = "Expreso";
                    mensaje = "";
                    tasa = this.state.tasaExpreso;
                    if (arreglaCuotas){
                        cuotas = this.maxCuotaNormal(monto, '07');
                    }
                    codtipo = "07";
                    montoCuota = this.calcCuotaNormal(cuotas, monto, tasa);
                    resultado = true;
                } else {
                    if (monto> montoExpreso && monto <= montoNormal){
                        tipo = "Normal"
                        mensaje = "";
                        tasa = this.state.tasaNormal;
                        if (arreglaCuotas){
                            cuotas = this.maxCuotaNormal(monto, '02');
                        }
                        codtipo = "02";
                        montoCuota = this.calcCuotaNormal(cuotas, monto, tasa);
                        resultado = true;
                    } else {
                        if (monto>montoNormal && monto<= montoEspecial){
                            tipo = "Especial";
                            mensaje = "";
                            tasa = this.state.tasaEspecial;
                            codtipo = "04";
                            if (arreglaCuotas){
                                cuotas = this.maxCuotaNormal(monto, '04');
                            }
                            montoCuota = this.calcCuotaNormal(cuotas, monto, tasa);
                            resultado = true;
                        } else {
                            mensaje = "Error. Monto mayor a su disponibilidad"   
                        }
                    }
                }
            }
        }
        
        this.setState({
            mensaje_error: mensaje,
            tipo: tipo,
            cuotas: cuotas,
            codtipo: codtipo,
            montoCuota: montoCuota,
            mensaje: (di_mensaje?mensaje:"")
            
        })
        return resultado
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        if (nombre=="valor" ){
            this.setState({
                [nombre]: target.value 
            }, function(){
                this.verifica(true, false);
            })
        } 
        if (nombre=="cuotas"){
            this.setState({
                [nombre]: target.value 
            }, function(){
                this.revisaCuotasNormales();
            })
        } 
        
    }

    cierraMensaje(){
        this.setState({
            mensaje: ""
        })
        if (this.state.mensaje_proceso!=null){
            this.state.mensaje_proceso();
        }
    }

    revisaCuotasNormales() {
        let resultado = false;
        var cuotas = parseInt(this.state.cuotas);
        var monto = parseFloat(this.state.valor);
        var tipo = this.state.codtipo;
        var escalas = this.state.escala;
        var maximo = 0;
        var mensaje = "";
        var a;
        for(a=0;a<escalas.length;a++){
            if (tipo==escalas[a].tipo && monto >= escalas[a].desde && monto <= escalas[a].hasta){
                if (escalas[a].cuotas>maximo){
                    maximo = escalas[a].cuotas;
                }
            }
        }
        //alert("cuotas: " + cuotas + ", maximo: " + maximo)
        if (cuotas > maximo) {
            mensaje = "Plazo mayor al permitido";
            this.setState({
                mensaje_error: mensaje
            })
        } else {
            resultado = this.verifica(false, false);
        }
        return resultado;
    }

    evtClick(){
        if (this.verifica(false, true)){
            let cuotas = parseInt(this.state.cuotas);
            if (cuotas>0 && this.revisaCuotasNormales()){
                this.setState({
                    pide_confirmacion: true
                })
            } else {
                this.setState({
                    mensaje: "Plazo incorrecto"
                })
            }
            
        }
    }

    cancelaConfirmacion(){
        this.setState({
            pide_confirmacion: false
        })
    }

    async solicita(){
        this.setState({
            buscandoDatos: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        miData.append("cuotas", parseInt(this.state.cuotas));
        miData.append("monto", parseFloat(this.state.valor));
        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/prestamos/solicitar", requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){
                    let txtmensaje = "Su Solicitud ha sido enviada con éxito."
                    if (data.mensaje=="04"){
                        txtmensaje = "Su Solicitud ha sido enviada con éxito. NOTA: LOS PRESTAMOS ESPECIALES REQUIEREN GARANTIA. PARA SER DESEMBOLSADO, DEBERA ENTREGAR LA DOCUMENTACION CORRESPONDIENTE."
                    } 
                    this.setState({
                        mensaje: txtmensaje,
                        clase_mensaje: "Info",
                        mensaje_proceso: this.props.procesoCierre
                    })
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        clase_mensaje: "Error",
                        mensaje_proceso: this.props.procesoCierre
                    })
                    
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    clase_mensaje: "Error"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Error: " + error,
                clase_mensaje: "Error"
            })
          }
          this.setState({
              buscandoDatos: false
          })
    }

    respuesta_correcta(){
        this.setState({
            pregunta_seguridad: false
        })
    }

    procesoCierre(){
        this.props.procesoCierre();
    }

    confirmaOk(){
        if (this.verifica(false, true)){
            let cuotas = parseInt(this.state.cuotas);
            if (cuotas>0 && this.revisaCuotasNormales()){
                this.solicita();
            } else {
                this.setState({
                    mensaje: "Plazo incorrecto"
                })
            }
            
        }
    }

    render(){
        const obj_mensaje = (this.state.mensaje.length>0?<Mensaje evtCerrar={this.cierraMensaje} texto={this.state.mensaje} clase={this.state.mensaje_clase} />:null)
        const obj_wait = (this.state.buscandoDatos?<Wait />: null);
        const obj_confirmacion = (this.state.pide_confirmacion? <Confirmacion texto="¿Desea solicitar este préstamo?" evtCerrar={this.cancelaConfirmacion} evtClick={this.confirmaOk} />: null);
        const obj_seguridad = (this.state.pregunta_seguridad? <Pregunta respuesta_correcta={this.respuesta_correcta} procesoCierre={this.procesoCierre} />: null);
        return(
            <div className="Fondo-Pres">
                {obj_mensaje}
                {obj_wait}
                {obj_confirmacion}
                {obj_seguridad}
                <div className="RowPres2">
                    <div className="Div-Etiq-Pres"><label className="EtiqPres b">Monto Solicitado:</label></div>
                    <div className="RowPres2b">
                        <MiEdit miNombre="valor" valor={this.state.valor} evtChange = {this.evtChange} />
                        <div className="Div-Error-Pres"><label>{this.state.mensaje_error}</label></div>
                    </div>
                </div>
                <div className="RowPres">
                    <div className="Div-Etiq-Pres"><label className="EtiqPres">Cuotas:</label></div>
                    <div><input type="number" autoComplete="off" className="form-control" id="cuotas" name="cuotas" value={this.state.cuotas} onChange={this.evtChange} /></div>
                </div>
                <div className="RowPres">
                    <div className="Div-Etiq-Pres"><label className="EtiqPres">Tipo:</label></div>
                    <div><input type="text" disabled autoComplete="off" className="form-control PresValores" name="tipo" value={this.state.tipo} /></div>
                </div>
                <div className="RowPres">
                    <div className="Div-Etiq-Pres"><label className="EtiqPres b">Monto Cuota:</label></div>
                    <div><input type="text" disabled autoComplete="off" className="form-control " name="montoCuota" id="montoCuota" value={this.state.montoCuota} /></div>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 200px 1fr'}}>
                    <div></div>
                    <MiBoton texto="Solicitar" evtClick={this.evtClick} enEspera={this.state.buscandoDatos} />

                    <div></div>
                </div>
            </div>
        )
    }
}

export default Prestamos;