import React from "react";
import "./../css/EstadoGeneral.css";
import RowTabla from './../components/RowTabla';
import Wait from './../components/Wait';

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
class EstadoGeneral extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            datos_ahorros: [],
            datos_prestamos: [],
            datos_facturas: [],
            buscandoDatos: true
        }
        this.actDatos = this.actDatos.bind(this);
        this.props.titulo("Estado de Cuenta General");
        this.actDatos();
    }

    async actDatos(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));

        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/Estados/general", requestOptions);
            if (response.ok){
                const data = await response.json();
                var a_ahorros = [];
                var a_prestamos = [];
                var a_facturas = [];
                if (data.respuesta=="S"){
                    if (data.ahorros.length>0){
                        const datos_ahorros = JSON.parse(data.ahorros)
                        var a;
                        for(a=0;a<datos_ahorros.length;a++){
                            let item = {};
                            item.id = datos_ahorros[a].codigo;
                            let este_tipo = datos_ahorros[a].TipoAhorro;
                            let este_cuota = datos_ahorros[a].Cuota
                            let este_balance = datos_ahorros[a].balance
                            item.columnas = [este_tipo, parseFloat(este_cuota).format(2,3,',','.'), parseFloat(este_balance).format(2,3,',','.')]
                            
                            a_ahorros.push(item);
                        }
                    }
                    if (data.prestamos.length>0){
                        const datos_prestamos = JSON.parse(data.prestamos)
                        var a;
                        for(a=0;a<datos_prestamos.length;a++){
                            let item = {};
                            item.id = datos_prestamos[a].prestamo;
                            let este_tipo = datos_prestamos[a].nombretipo;
                            let este_termino = this.formatoFecha(datos_prestamos[a].fechatermino);
                            let este_saldar = datos_prestamos[a].deudasaldar
                            item.columnas = [este_tipo, datos_prestamos[a].plazo, este_termino, parseFloat(este_saldar).format(2,3,',','.')]
                            
                            a_prestamos.push(item);
                        }
                    }

                    if (data.facturas.length>0){
                        const datos_facturas = JSON.parse(data.facturas)
                        var a;
                        for(a=0;a<datos_facturas.length;a++){
                            let item = {};
                            item.id = datos_facturas[a].codigo;
                            let este_numero = datos_facturas[a].numero;
                            let este_fecha = this.formatoFecha(datos_facturas[a].fecha);
                            let este_balance = datos_facturas[a].balance
                            item.columnas = [este_numero, este_fecha, parseFloat(este_balance).format(2,3,',','.')]
                            
                            a_facturas.push(item);
                        }
                    }

                    

                    this.setState({
                        datos_ahorros: a_ahorros,
                        datos_prestamos: a_prestamos,
                        datos_facturas: a_facturas,
                        buscandoDatos: false
                    });
                } else {
                    //alert("mensaje: " + data.mensaje)
                    
                }
            } else {
                //alert("Error de conexión...");
            }
          } catch(error){
            alert("Error: " + error);
          }
    }

    formatoFecha(fecha) {
        let cual = new Date(fecha);
        var dd = cual.getDate();
        var mm = cual.getMonth() + 1;
    
        var yyyy = cual.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var today = dd + '/' + mm + '/' + yyyy;
        return today;
    }

    render(){
        var datos_ahorros = {
            titulo: 'AHORROS',
            titulos: [
                {texto: 'Tipo', ancho: '1fr'},
                {texto: 'Cuota', ancho: '1fr'},
                {texto: 'Balance', ancho: '1fr'}
                ],
            justifica:  ['L', 'D', 'D'],
            datos: this.state.datos_ahorros
        }; 
        var a_foot_ar = ["Total:", "", "sum"];
        var a_foot_pr = ["Total:", "", "", "sum"];
        var a_foot_fac = ["Total:", "", "sum"];

        var datos_prestamos = {
            titulo: 'PRESTAMOS',
            titulos: [
                {texto: 'Tipo', ancho: '1fr'},
                {texto: 'Plazo', ancho: '1fr'},
                {texto: 'Fecha Termino', ancho: '1fr'},
                {texto: 'Para Saldar', ancho: '1fr'}
                ],
            justifica:  ['L', 'C', 'C', 'D'],
            datos: this.state.datos_prestamos
        }; 
        var datos_facturas = {
            titulo: 'FACTURAS',
            titulos: [
                {texto: 'Factura', ancho: '1fr'},
                {texto: 'Fecha', ancho: '1fr'},
                {texto: 'Balance', ancho: '1fr'}
                ],
            justifica:  ['L', 'D', 'D'],
            datos: this.state.datos_facturas
        }; 
        const obj_wait = (this.state.buscandoDatos?<Wait />: null);
        const obj_ah = (this.state.datos_ahorros.length>0?<div style={{marginBottom: '20px'}}><RowTabla footer={a_foot_ar} data = {datos_ahorros}/></div>:null)
        const obj_pr = (this.state.datos_prestamos.length>0?<div style={{marginBottom: '20px'}}><RowTabla footer={a_foot_pr} data = {datos_prestamos}/></div>:null);
        const obj_fc = (this.state.datos_facturas.length>0?<div><RowTabla footer={a_foot_fac} data = {datos_facturas}/></div>: null)
        return(
            <div className="Div-Estado">
                {obj_wait}
                {obj_ah}
                {obj_pr}
                {obj_fc}
                
                
            </div>
        )
    }

}

export default EstadoGeneral;