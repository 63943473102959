import React from 'react';
import './../css/MiBoton.css';
import Wait from './Wait';

class MiBoton extends React.Component{
    constructor(props){
        super(props);
        this.evtClick = this.evtClick.bind(this);
    }

    evtClick(){
        this.props.evtClick(this.props.miNombre);
    }

    render(){
        const obj_wait = (this.props.enEspera?<Wait />:null);
        return(
            <div className="MiBoton" onClick={(!this.props.enEspera?this.evtClick:null)} name={this.props.miNombre}>
                {obj_wait}
                {this.props.texto}
            </div>
        )
    }
}

export default MiBoton;