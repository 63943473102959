import React from "react";
import './../css/NuevosSocios.css';
import MiSelect from "./MiSelect";
import MiBoton from './MiBoton'
import Confirmacion from "./Confirmacion";
import MiEdit from "./MiEdit";
import Mensaje from './Mensaje.jsx';
import Wait from "./Wait";

class NuevosSocios extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            codigo: "",
            cedula: "",
            nombres: "",
            email: "",
            apellidos: "",
            clave: "",
            clave_repetir: "",
            direccion: "",
            tel_casa: "",
            celular: "",
            tel_oficina: "",
            fecha_nacimiento: "",
            sexo: "0",
            estado: "0",
            departamento: "0",
            categoria: "0",
            sueldo: "0.00",
            beneficiario_nombre: "",
            beneficiario_cedula: "",
            beneficiario_parentezco: 0,
            beneficiario2_nombre: "",
            beneficiario2_cedula: "",
            beneficiario2_parentezco: 0,
            ahorro_normal: "0.00",
            ahorro_especial: "0.00",
            ahorro_escolar: "0.00",
            ahorro_clubviajes: "0.00",
            ahorro_navidad: "0.00",
            ahorro_infantil: "0.00",
            ayuda_mutua1_nombres: "",
            ayuda_mutua1_cedula: "",
            ayuda_mutua1_parentezco: "",
            ayuda_mutua2_nombres: "",
            ayuda_mutua2_cedula: "",
            ayuda_mutua2_parentezco: "",
            ayuda_mutua_dependiente1_nombre: "",
            ayuda_mutua_dependiente1_cedula: "",
            ayuda_mutua_dependiente1_fecha_nacimiento: "",
            ayuda_mutua_dependiente1_parentezco: "",
            ayuda_mutua_dependiente2_nombre: "",
            ayuda_mutua_dependiente2_cedula: "",
            ayuda_mutua_dependiente2_fecha_nacimiento: "",
            ayuda_mutua_dependiente2_parentezco: "",
            ayuda_mutua_dependiente3_nombre: "",
            ayuda_mutua_dependiente3_cedula: "",
            ayuda_mutua_dependiente3_fecha_nacimiento: "",
            ayuda_mutua_dependiente3_parentezco: "",
            ayuda_mutua_dependiente4_nombre: "",
            ayuda_mutua_dependiente4_cedula: "",
            ayuda_mutua_dependiente4_fecha_nacimiento: "",
            ayuda_mutua_dependiente4_parentezco: "",
            ayuda_mutua_dependiente5_nombre: "",
            ayuda_mutua_dependiente5_cedula: "",
            ayuda_mutua_dependiente5_fecha_nacimiento: "",
            ayuda_mutua_dependiente5_parentezco: "",
            ayuda_mutua_dependiente6_nombre: "",
            ayuda_mutua_dependiente6_cedula: "",
            ayuda_mutua_dependiente6_fecha_nacimiento: "",
            ayuda_mutua_dependiente6_parentezco: "",
            ayuda_mutua_dependiente7_nombre: "",
            ayuda_mutua_dependiente7_cedula: "",
            ayuda_mutua_dependiente7_fecha_nacimiento: "",
            ayuda_mutua_dependiente7_parentezco: "",
            ayuda_mutua_dependiente8_nombre: "",
            ayuda_mutua_dependiente8_cedula: "",
            ayuda_mutua_dependiente8_fecha_nacimiento: "",
            ayuda_mutua_dependiente8_parentezco: "",
            ayuda_mutua_dependiente9_nombre: "",
            ayuda_mutua_dependiente9_cedula: "",
            ayuda_mutua_dependiente9_fecha_nacimiento: "",
            ayuda_mutua_dependiente9_parentezco: "",
            ayuda_mutua_dependiente10_nombre: "",
            ayuda_mutua_dependiente10_cedula: "",
            ayuda_mutua_dependiente10_fecha_nacimiento: "",
            ayuda_mutua_dependiente10_parentezco: "",
            clave_mensaje: "Digite la clave",
            clave2_mensaje: "",
            clave_fuerza: 0,
            pide_confirmacion: false,
            mensaje: "",
            mensaje_clase : "Error",
            mensaje_proceso: null,
            procesando: true,
            datos_departamentos: [],
            ayudamutua: "checked",
            ayuda_habilitada: "",
            a_paren : ["", "Esposo/a",  "Hijo/a",  "Madre",  "Padre",  "Hermano/a"],
            lv_trabajo_publico: 0,
            lv_manejo_recursos: 0,
            lv_aspira_puesto_publico: 0,
            lv_familiares: 0,
            lv_familiares_detalle: '',
            lv_otra_actividad: 0,
            lv_otra_actividad_detalle: '',
            lv_otra_actividad_ingresos_rd: '0.00',
            lv_otra_actividad_ingresos_us: '0.00'

        }
        this.evtClick = this.evtClick.bind(this);
        this.evtChange = this.evtChange.bind(this);
        this.puedeGrabar = this.puedeGrabar.bind(this);
        this.password_revisa = this.password_revisa.bind(this);
        this.cancelaConfirmacion = this.cancelaConfirmacion.bind(this);
        this.confirmaOk = this.confirmaOk.bind(this);
        this.cerrarMensaje = this.cerrarMensaje.bind(this);
        this.loadDepartamentos = this.loadDepartamentos.bind(this);
        this.cambiaAyudaMutua = this.cambiaAyudaMutua.bind(this);
        this.actAyudaMutua = this.actAyudaMutua.bind(this);
        this.lv_cambios = this.lv_cambios.bind(this);

        this.loadDepartamentos();
    }

    lv_cambios(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        })
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;

        this.setState({
            [nombre]: target.value.toUpperCase()
        })
        if (nombre=="clave"){
            this.password_revisa(target.value);
        }
        if (nombre=="clave_repetir"){
            let clave = this.state.clave;
            if (target.value!=clave){
                this.setState({
                    clave2_mensaje: "Claves no coinciden"
                })
            } else {
                this.setState({
                    clave2_mensaje: ""
                })
            }
        }
    }

    evtClick(event){
        if (this.puedeGrabar()){
            this.setState({
                pide_confirmacion: true
            })
        }
        
    }
    cancelaConfirmacion(){
        this.setState({
            pide_confirmacion: false
        })
    }
    confirmaOk(){
        if (this.puedeGrabar()){
            this.grabaSolicitud();
        }
    }

    password_revisa(pwd) {
        var mensaje = "";
        var fuerza = 0;
        var strongRegex = new RegExp("^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
        var mediumRegex = new RegExp("^(?=.{10,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
        var enoughRegex = new RegExp("(?=.{8,}).*", "g");
        if (pwd.length == 0) {
            mensaje = 'Digite la clave';
            fuerza = 0;
        } else if (false == enoughRegex.test(pwd)) {
            mensaje = 'Muy corta';
            fuerza = 0;
        } else if (strongRegex.test(pwd)) {
            mensaje = 'Fuerte!';
            fuerza = 2;
        } else if (mediumRegex.test(pwd)) {
            mensaje = 'Medio';
            fuerza = 1;
        } else {
            mensaje = 'Débil!';
            fuerza = 0;
        }
        
        this.setState({
            clave_mensaje: mensaje,
            clave_fuerza: fuerza
        })
    }

    puedeGrabar(){
        let puede = true;
        let mensaje = "";
        let socio = this.state.codigo;
        let cedula = this.state.cedula//.replaceAll("-", "").replaceAll(" ", "");
        let nombre = this.state.nombres;
        let apellido = this.state.apellidos;
        let email = this.state.email;
        let clave = this.state.clave;
        let repetir = this.state.clave_repetir;
        let direccion = this.state.direccion;
        let celular = this.state.celular;
        let nacimiento = this.state.fecha_nacimiento;
        let sexo = this.state.sexo;
        let estado = this.state.estado;
        let departamento = this.state.departamento;
        let categoria = this.state.categoria;
        let ahorronormal = parseFloat(this.state.ahorro_normal);
        let lv_trabajo_publico = this.state.lv_trabajo_publico;
        let lv_manejo_recursos = this.state.lv_manejo_recursos;
        let lv_aspira_puesto_publico = this.state.lv_aspira_puesto_publico;
        let lv_familiares = this.state.lv_familiares;
        let lv_familiares_detalle = this.state.lv_familiares_detalle;
        let lv_otra_actividad = this.state.lv_otra_actividad;
        let lv_otra_actividad_detalle = this.state.lv_otra_actividad_detalle;
        let lv_otra_actividad_ingresos_rd = parseFloat(this.state.lv_otra_actividad_ingresos_rd);
        let lv_otra_actividad_ingresos_us = parseFloat(this.state.lv_otra_actividad_ingresos_us);

        this.password_revisa(clave);
        if (puede && socio.length==0){
            puede = false;
            mensaje = "Debe digitar su código de empleado/profesor."
        }
        if (puede && cedula.length!=11){
            puede = false;
            mensaje = "Debe digitar su cédula de identidad."
        }
        if (puede && nombre.length==0){
            puede = false;
            mensaje = "Debe digitar su nombre."
        }
        if (puede && apellido.length==0){
            puede = false;
            mensaje = "Debe digitar su apellido."
        }
        if (puede && email.length==0){
            puede = false;
            mensaje = "Debe digitar su correo electrónico."
        } else {
            if (puede && (email.indexOf("@")==-1 || email.indexOf(".")==-1 || email.indexOf(" ")>-1)){
                puede = false;
                mensaje = "Formato de email incorrecto."
            }
        }
        if (puede && clave.length==0){
            puede = false;
            mensaje = "Debe digitar su clave"
        } else {
            if (puede && this.state.clave_fuerza<1){
                puede = false;
                mensaje = "Su clave es muy débil"
            }
        }
        if (puede && clave!=repetir){
            puede = false;
            mensaje = "Claves no coinciden"
        }
        if (puede && direccion.length==0){
            puede = false;
            mensaje = "Debe digitar su dirección"
        }
        if (puede && celular.length==0){
            puede = false;
            mensaje = "Debe digitar su celular"
        }
        if (puede && nacimiento == ""){
            puede = false;
            mensaje = "Debe digitar su fecha de nacimiento"
        }
        if (puede && estado=="0"){
            puede = false;
            mensaje = "Debe seleccionar su estado civil"
        }
        if (puede && sexo =="0"){
            puede = false;
            mensaje = "Debe seleccionar su sexo"
        }
        if (puede && departamento=="0"){
            puede = false;
            mensaje = "Debe seleccionar su departamento"
        }
        if (puede && categoria=="0"){
            puede = false;
            mensaje = "Debe seleccionar su categoría"
        }
        if (puede && ahorronormal<200){
            puede = false;
            mensaje = "El mínimo del ahorro normal es de RD$200.00"
        }
        if (puede && lv_trabajo_publico==0){
            puede = false;
            mensaje = "Debe responder las preguntas sobre Lavado de Activos"
            alert(1)
        }
        if (puede && lv_manejo_recursos==0){
            puede = false;
            mensaje = "Debe responder las preguntas sobre Lavado de Activos"
            alert(2)
        }
        if (puede && lv_aspira_puesto_publico==0){
            puede = false;
            mensaje = "Debe responder las preguntas sobre Lavado de Activos"
            alert(3)
        }
        if (puede && lv_familiares==0){
            puede = false;
            mensaje = "Debe responder las preguntas sobre Lavado de Activos"
            alert(4)
        }
        if (puede && lv_familiares==1 && lv_familiares_detalle.length==0){
            puede = false;
            mensaje = "Debe responder las preguntas sobre Lavado de Activos"
            alert(5)
        }
        if (puede && lv_otra_actividad==0){
            puede = false;
            mensaje = "Debe responder las preguntas sobre Lavado de Activos"
            alert(6)
        }
        if (puede && lv_otra_actividad==1 && lv_otra_actividad_detalle.length==0){
            puede = false;
            mensaje = "Debe responder las preguntas sobre Lavado de Activos"
            alert(7)
        }
        if (puede && lv_otra_actividad==1 && lv_otra_actividad_ingresos_rd+lv_otra_actividad_ingresos_us==0){
            puede = false;
            mensaje = "Debe responder las preguntas sobre Lavado de Activos"
            alert(8)
        }
        this.setState({
            mensaje: mensaje
        })
        return puede;
    }

    cerrarMensaje(){
        this.setState({
            mensaje: ""
        });
        if (this.state.mensaje_proceso!=null){
            this.state.mensaje_proceso();
        }
    }

    async loadDepartamentos(){
        this.setState({
            procesando: true
        })
        var a_deps = [];

        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/nuevos/departamentos";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data[0].respuesta=="S"){
                    var a;
                    for(a=0;a<data.length; a++){
                        let item = {};
                        item.codigo = data[a].codigo;
                        item.nombre = data[a].nombre;
                        item.padre = 0;
                        a_deps.push(item);
                    }
                    
                    this.setState({
                        datos_departamentos: a_deps
                    });
                } else {
                    //alert("mensaje: " + data.mensaje)
                    
                }
            } else {
                //alert("Error de conexión...");
            }
          } catch(error){
            //alert("Error: " + error);
          }
          this.setState({
              procesando: false
          })
    }

    cambiaAyudaMutua(){
        let ahora = this.state.ayudamutua;
        if (ahora=="checked"){
            ahora = ""
        } else {
            ahora = "checked"
        }
        this.setState({
            ayudamutua: ahora
        }, this.actAyudaMutua);
    }

    actAyudaMutua(){
        let a = this.state.ayudamutua;
        if (a=="checked"){
            this.setState({
                ayuda_habilitada: ""
            })
        } else {
            this.setState({
                ayuda_habilitada: "disabled"
            })
        }
    }

    json_ayuda_mutua(){
        let r = "";
        let ayuda = this.state.ayudamutua;
        let datos = [];
        if (ayuda=="checked"){
            if (this.state.ayuda_mutua_dependiente1_nombre.length>0){let item = {}; item.nombre=this.state.ayuda_mutua_dependiente1_nombre; item.cedula = this.state.ayuda_mutua_dependiente1_cedula; item.nacimiento=this.state.ayuda_mutua_dependiente1_fecha_nacimiento; item.parentezco = this.state.ayuda_mutua_dependiente1_parentezco; datos.push(item)}
            if (this.state.ayuda_mutua_dependiente2_nombre.length>0){let item = {}; item.nombre=this.state.ayuda_mutua_dependiente2_nombre; item.cedula = this.state.ayuda_mutua_dependiente2_cedula; item.nacimiento=this.state.ayuda_mutua_dependiente2_fecha_nacimiento; item.parentezco = this.state.ayuda_mutua_dependiente2_parentezco; datos.push(item)}
            if (this.state.ayuda_mutua_dependiente3_nombre.length>0){let item = {}; item.nombre=this.state.ayuda_mutua_dependiente3_nombre; item.cedula = this.state.ayuda_mutua_dependiente3_cedula; item.nacimiento=this.state.ayuda_mutua_dependiente3_fecha_nacimiento; item.parentezco = this.state.ayuda_mutua_dependiente3_parentezco; datos.push(item)}
            if (this.state.ayuda_mutua_dependiente4_nombre.length>0){let item = {}; item.nombre=this.state.ayuda_mutua_dependiente4_nombre; item.cedula = this.state.ayuda_mutua_dependiente4_cedula; item.nacimiento=this.state.ayuda_mutua_dependiente4_fecha_nacimiento; item.parentezco = this.state.ayuda_mutua_dependiente4_parentezco; datos.push(item)}
            if (this.state.ayuda_mutua_dependiente5_nombre.length>0){let item = {}; item.nombre=this.state.ayuda_mutua_dependiente5_nombre; item.cedula = this.state.ayuda_mutua_dependiente5_cedula; item.nacimiento=this.state.ayuda_mutua_dependiente5_fecha_nacimiento; item.parentezco = this.state.ayuda_mutua_dependiente5_parentezco; datos.push(item)}
            if (this.state.ayuda_mutua_dependiente6_nombre.length>0){let item = {}; item.nombre=this.state.ayuda_mutua_dependiente6_nombre; item.cedula = this.state.ayuda_mutua_dependiente6_cedula; item.nacimiento=this.state.ayuda_mutua_dependiente6_fecha_nacimiento; item.parentezco = this.state.ayuda_mutua_dependiente6_parentezco; datos.push(item)}
            if (this.state.ayuda_mutua_dependiente7_nombre.length>0){let item = {}; item.nombre=this.state.ayuda_mutua_dependiente7_nombre; item.cedula = this.state.ayuda_mutua_dependiente7_cedula; item.nacimiento=this.state.ayuda_mutua_dependiente7_fecha_nacimiento; item.parentezco = this.state.ayuda_mutua_dependiente7_parentezco; datos.push(item)}
            if (this.state.ayuda_mutua_dependiente8_nombre.length>0){let item = {}; item.nombre=this.state.ayuda_mutua_dependiente8_nombre; item.cedula = this.state.ayuda_mutua_dependiente8_cedula; item.nacimiento=this.state.ayuda_mutua_dependiente8_fecha_nacimiento; item.parentezco = this.state.ayuda_mutua_dependiente8_parentezco; datos.push(item)}
            if (this.state.ayuda_mutua_dependiente9_nombre.length>0){let item = {}; item.nombre=this.state.ayuda_mutua_dependiente9_nombre; item.cedula = this.state.ayuda_mutua_dependiente9_cedula; item.nacimiento=this.state.ayuda_mutua_dependiente9_fecha_nacimiento; item.parentezco = this.state.ayuda_mutua_dependiente9_parentezco; datos.push(item)}
            if (this.state.ayuda_mutua_dependiente10_nombre.length>0){let item = {}; item.nombre=this.state.ayuda_mutua_dependiente10_nombre; item.cedula = this.state.ayuda_mutua_dependiente10_cedula; item.nacimiento=this.state.ayuda_mutua_dependiente10_fecha_nacimiento; item.parentezco = this.state.ayuda_mutua_dependiente10_parentezco; datos.push(item)}
        }
        if (datos.length==0){
            let item = {}
            item.nombre = '';
            item.cedula = '-1';
            item.nacimiento ='';
            item.parentezco = ''
            datos.push(item);
        }
        r = JSON.stringify(datos);
        return r;
    }


    async grabaSolicitud(){
        this.setState({
            procesando: true
        })
        let lv_trabajo_publico = this.state.lv_trabajo_publico;
        let lv_manejo_recursos = this.state.lv_manejo_recursos;
        let lv_aspira_puesto_publico = this.state.lv_aspira_puesto_publico;
        let lv_familiares = this.state.lv_familiares;
        let lv_familiares_detalle = this.state.lv_familiares_detalle;
        let lv_otra_actividad = this.state.lv_otra_actividad;
        let lv_otra_actividad_detalle = this.state.lv_otra_actividad_detalle;
        let lv_otra_actividad_ingresos_rd = parseFloat(this.state.lv_otra_actividad_ingresos_rd);
        let lv_otra_actividad_ingresos_us = parseFloat(this.state.lv_otra_actividad_ingresos_us);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var miData = new URLSearchParams();
        miData.append("socio", this.state.codigo);
        miData.append("nombre", this.state.nombres);
        miData.append("cedula", this.state.cedula);
        miData.append("email", this.state.email);
        miData.append("direccion", this.state.direccion);
        miData.append("telefono", this.state.tel_casa);
        miData.append("celular", this.state.celular);
        miData.append("teloficina", this.state.tel_oficina);
        miData.append("nacimiento", this.state.fecha_nacimiento);
        miData.append("sexo", this.state.sexo);
        miData.append("estadocivil", this.state.estado);
        miData.append("departamento", this.state.departamento);
        miData.append("sueldo", this.state.sueldo);
        miData.append("nombrebeneficiario", this.state.beneficiario_nombre);
        miData.append("cedulabeneficiario", this.state.beneficiario_cedula);
        miData.append("parentezcobeneficiario", this.state.a_paren[parseInt(this.state.beneficiario_parentezco)]);
        miData.append("nombrebeneficiario2", this.state.beneficiario2_nombre);
        miData.append("cedulabeneficiario2", this.state.beneficiario2_cedula);
        miData.append("parentezcobeneficiario2",  this.state.a_paren[parseInt(this.state.beneficiario2_parentezco)]);
        miData.append("ahorronormal", this.state.ahorro_normal);
        miData.append("ahorroespecial", this.state.ahorro_especial);
        miData.append("ahorroescolar", this.state.ahorro_escolar);
        miData.append("ahorroviajes", this.state.ahorro_clubviajes);
        miData.append("ahorronavideno", this.state.ahorro_navidad);
        miData.append("ahorroinfantil", this.state.ahorro_infantil);
        miData.append("ayudamutua", (this.state.ayudamutua=="checked"?-1: 0));
        miData.append("categoria", this.state.categoria);
        miData.append("clave", this.state.clave);
        miData.append("apellido", this.state.apellidos);
        miData.append("ayudamutua1_nombres", this.state.ayuda_mutua1_nombres);
        miData.append("ayudamutua1_cedula", this.state.ayuda_mutua1_cedula);
        miData.append("ayudamutua1_parentezco", this.state.ayuda_mutua1_parentezco);
        miData.append("ayudamutua2_nombres", this.state.ayuda_mutua2_nombres);
        miData.append("ayudamutua2_cedula", this.state.ayuda_mutua2_cedula);
        miData.append("ayudamutua2_parentezco", this.state.ayuda_mutua2_parentezco);


        miData.append("trabajo_publico", lv_trabajo_publico);
        miData.append("manejo_recursos", lv_manejo_recursos);
        miData.append("aspira_puesto_publico", lv_aspira_puesto_publico);
        miData.append("familiares", lv_familiares);
        miData.append("familiares_detalle", lv_familiares_detalle);
        miData.append("otra_actividad", lv_otra_actividad);
        miData.append("otra_actividad_detalle", lv_otra_actividad_detalle);
        miData.append("otra_actividad_ingresos_rd", lv_otra_actividad_ingresos_rd);
        miData.append("otra_actividad_ingresos_us", lv_otra_actividad_ingresos_us);

        miData.append("json_dependientes", this.json_ayuda_mutua());

        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/nuevos/grabar";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data.respuesta=="S"){
                    this.setState({
                        procesando: false,
                        mensaje: "Su solicitud ha sido enviada exitosamente!",
                        mensaje_clase: "Info",
                        mensaje_proceso : this.props.cierre
                    })

                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        procesando: false
                    })
                }
            } else {
                //alert("Error de conexión...");
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    procesando: false
                })
            }
          } catch(error){
            //alert("Error: " + error);
            this.setState({
                mensaje: "Ha ocurrido un error en la conexión. Por favor intente más tarde",
                procesando: false
            })
          }
    }
    

    render(){
        const datos_sex = [
           {codigo:"M", nombre: "Masculino", padre:0},
          {codigo:"F", nombre: "Femenino", padre:0}
        ]
        const datos_departamento = this.state.datos_departamentos;
        const datos_estado = [
           {codigo:"S", nombre: "Soltero", padre:0},
          {codigo:"C", nombre: "Casado", padre:0},
          {codigo:"V", nombre: "Viudo", padre:0},
          {codigo:"D", nombre: "Divorciado", padre:0},
        ]
        const datos_categoria = [
            {codigo:"A", nombre: "Administrativo", padre:0},
           {codigo:"D", nombre: "Docente", padre:0},
           {codigo:"P", nombre: "Jubilado", padre:0},
         ]
         const datos_parentezco = [
            {codigo:1 , nombre: "Esposo/a", padre:0},
           {codigo:2, nombre: "Hijo/a", padre:0},
           {codigo:3, nombre: "Madre", padre:0},
           {codigo:4, nombre: "Padre", padre:0},
           {codigo:5, nombre: "Hermano/a", padre:0},
         ]
         const datos_parentezco2 = [
            {codigo:"1", nombre: "Socio(a)", padre:0},
           {codigo:"2", nombre: "Esposo(a)", padre:0},
           {codigo:"3", nombre: "Hijo(a) Menor Edad", padre:0},
           {codigo:"4", nombre: "Hijo(a) Mayor Edad", padre:0},
           {codigo:"5", nombre: "Padre", padre:0},
           {codigo:"6", nombre: "Madre", padre:0},
           {codigo:"7", nombre: "Otro", padre:0},
         ]
        const obj_confirmacion = (this.state.pide_confirmacion? <Confirmacion texto="¿Desea enviar su solicitud?" evtCerrar={this.cancelaConfirmacion} evtClick={this.confirmaOk} />: null);
        const obj_mensaje = (this.state.mensaje.length>0 ? <Mensaje clase={this.state.mensaje_clase} texto={this.state.mensaje} evtCerrar={this.cerrarMensaje} />: null);
        const obj_wait = (this.state.procesando?<Wait />: null);
        return(
            <div className="Div-Fondo-Nuevo">
                {obj_confirmacion}
                {obj_mensaje}
                {obj_wait}
                <div className="Div-Nuevo">
                    <div className="rowNewCab">
                        <div className="divBotGrabar"><MiBoton texto="Procesar" evtClick={this.evtClick} /></div>
                        <h4 style={{width: "100%", textAlign: "center", marginBottom: "20px"}}>Inscripción de nuevos socios</h4>
                    </div>
                    <div className="Div-Nuevo-Datos">
                        <div className="newGrupo">
                            <div className="newTitulo">Información de la Cuenta</div>
                            <div className="newRows">
                                <div><label className="newLabel">Código de la UASD:</label></div>
                                <div><input type="text" autoComplete="off" onChange={this.evtChange} value={this.state.codigo} name="codigo" className="form-control newPeq newVal"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Cédula:</label></div>
                                <div><input type="text" autoComplete="off" onChange={this.evtChange} value={this.state.cedula} name="cedula" className="form-control newPeq newVal"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Nombres:</label></div>
                                <div><input type="text" autoComplete="off" onChange={this.evtChange} value={this.state.nombres} name="nombres" className="form-control newVal"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Apellidos:</label></div>
                                <div><input type="text" autoComplete="off" onChange={this.evtChange} value={this.state.apellidos} name="apellidos" className="form-control newVal"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Email:</label></div>
                                <div><input type="text" autoComplete="off" onChange={this.evtChange} value={this.state.email} name="email" className="form-control newVal"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Contraseña:</label></div>
                                <div className="divNewDat">
                                    <div><input type="password" onChange={this.evtChange} value={this.state.clave} name="clave" className="form-control newPeq"/></div>
                                    <div className="divDatMensaje">{this.state.clave_mensaje}</div>
                                </div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Repetir Contraseña:</label></div>
                                <div className="divNewDat">
                                    <div><input type="password" onChange={this.evtChange} value={this.state.clave_repetir} name="clave_repetir" className="form-control newPeq"/></div>
                                    <div className="divDatMensaje">{this.state.clave2_mensaje}</div>
                                </div>
                            </div>
                        </div>
                        <div className="newGrupo">
                            <div className="newTitulo">Información General</div>
                            <div className="newRows">
                                <div><label className="newLabel">Dirección:</label></div>
                                <div><textarea onChange={this.evtChange} name="direccion" value={this.state.direccion} className="form-control newVal"></textarea></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Tel. Casa:</label></div>
                                <div><input type="text" autoComplete="off" onChange={this.evtChange} value={this.state.tel_casa} name="tel_casa" className="form-control newPeq newVal"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Celular:</label></div>
                                <div><input type="text" autoComplete="off" onChange={this.evtChange} value={this.state.celular} name="celular" className="form-control newPeq newVal"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Tel. Oficina:</label></div>
                                <div><input className="form-control newPeq"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Fecha Nacimiento:</label></div>
                                <div><input type="date" name="fecha_nacimiento" onChange={this.evtChange} value={this.state.fecha_nacimiento} className="form-control newPeq"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Sexo:</label></div>
                                <MiSelect filas={datos_sex} padre={0} miNombre="sexo" evtCambios={this.evtChange} ancho={"200px"} valor={this.state.sexo} />
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Estado Civil:</label></div>
                                <MiSelect filas={datos_estado} padre={0} miNombre="estado" evtCambios={this.evtChange} ancho={"200px"} valor={this.state.estado} />
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Departamento:</label></div>
                                <MiSelect filas={datos_departamento} padre={0} miNombre="departamento" evtCambios={this.evtChange} valor={this.state.departamento} />
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Categoría:</label></div>
                                <MiSelect filas={datos_categoria} padre={0} miNombre="categoria" evtCambios={this.evtChange} valor={this.state.categoria} />
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Sueldo:</label></div>
                                <MiEdit miNombre="sueldo" valor={this.state.sueldo} evtChange={this.evtChange} />
                            </div>
                            <div><label className="newLabel">En caso de fallecimiento, mis ahorros sean pagados a</label></div>
                            <div className="newRows">
                                <div><label className="newLabel">Nombre:</label></div>
                                <div><input type="text" autoComplete="off" onChange={this.evtChange} value={this.state.beneficiario_nombre} name="beneficiario_nombre" className="form-control newVal"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Cédula:</label></div>
                                <div><input type="text" autoComplete="off" onChange={this.evtChange} value={this.state.beneficiario_cedula} name="beneficiario_cedula" className="form-control newPeq newVal"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Paretezco:</label></div>
                                <MiSelect filas={datos_parentezco} padre={0} miNombre="beneficiario_parentezco" evtCambios={this.evtChange} ancho="200px" valor={this.state.beneficiario_parentezco} />
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Nombre:</label></div>
                                <div><input type="text" autoComplete="off" onChange={this.evtChange} value={this.state.beneficiario2_nombre} name="beneficiario2_nombre" className="form-control newVal"/></div>
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Cédula:</label></div>
                                <div><input type="text" autoComplete="off" onChange={this.evtChange} value={this.state.beneficiario2_cedula} name="beneficiario2_cedula" className="form-control newPeq newVal"/></div>                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Paretezco:</label></div>
                                <MiSelect filas={datos_parentezco} padre={0} miNombre="beneficiario2_parentezco" evtCambios={this.evtChange} ancho="200px" valor={this.state.beneficiario2_parentezco} />
                            </div>
                        </div>
                        <div className="newGrupo">
                            <div className="newTitulo">Información de Ahorros</div>
                            <div className="newRows">
                                <div><label className="newLabel">Ahorro Normal (obligatorio):</label></div>
                                <MiEdit miNombre="ahorro_normal" valor={this.state.ahorro_normal} evtChange={this.evtChange} />
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Ahorro Especial (opcional):</label></div>
                                <MiEdit miNombre="ahorro_especial" valor={this.state.ahorro_especial} evtChange={this.evtChange} />
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Ahorro Navideño (opcional):</label></div>
                                <MiEdit miNombre="ahorro_navidad" valor={this.state.ahorro_navidad} evtChange={this.evtChange} />
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Ahorro Escolar (opcional):</label></div>
                                <MiEdit miNombre="ahorro_escolar" valor={this.state.ahorro_escolar} evtChange={this.evtChange} />
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Ahorro Club de Viajes (opcional):</label></div>
                                <MiEdit miNombre="ahorro_clubviajes" valor={this.state.ahorro_clubviajes} evtChange={this.evtChange} />
                            </div>
                            <div className="newRows">
                                <div><label className="newLabel">Ahorro Infantil (opcional):</label></div>
                                <MiEdit miNombre="ahorro_infantil" valor={this.state.ahorro_infantil} evtChange={this.evtChange} />
                            </div>

                        </div>
                        <div className="newGrupo">
                            <div className="newTitulo">
                                <input type='checkbox' id='ayudamutua' name="ayudamutua" checked={this.state.ayudamutua} onChange={this.cambiaAyudaMutua} />
                                <label class='newLabel' for='ayudamutua'>Ayuda Mutua</label>
                            </div>
                            <h6>Beneficiarios Ayuda Mutua</h6>
                            <div className="rowAyuda titulo">
                                <div>Nombres y Apellidos</div>
                                <div>Cédula</div>
                                <div>Parentezco</div>
                            </div>
                            <div className="rowAyuda">
                                <div><input type="text" placeholder="Nombres" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua1_nombres} name="ayuda_mutua1_nombres" className="form-control newVal"/></div>
                                <div><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua1_cedula} name="ayuda_mutua1_cedula" className="form-control newVal"/></div>
                                <div><input type="text" placeholder="Parentezco" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua1_parentezco} name="ayuda_mutua1_parentezco" className="form-control newVal"/></div>
                            </div>
                            <div className="rowAyuda">
                                <div><input type="text" placeholder="Nombres" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua2_nombres} name="ayuda_mutua2_nombres" className="form-control newVal"/></div>
                                <div><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua2_cedula} name="ayuda_mutua2_cedula" className="form-control newVal"/></div>
                                <div><input type="text" placeholder="Parentezco" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua2_parentezco} name="ayuda_mutua2_parentezco" className="form-control newVal"/></div>
                            </div>
                            <h6>Dependientes</h6>
                            <div className="rowAyuda2 titulo">
                                <div className="div_ayNom">Nombres y Apellidos</div>
                                <div className="div_ayCed">Cédula</div>
                                <div className="div_ayNac">F. Nacimiento</div>
                                <div className="div_aypar">Parentezco</div>
                            </div>
                            <div className="rowAyuda2">
                                <div className="div_ayNom"><input type="text" placeholder="Nombre" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente1_nombre} name="ayuda_mutua_dependiente1_nombre" className="form-control newVal"/></div>
                                <div className="div_ayCed"><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente1_cedula} name="ayuda_mutua_dependiente1_cedula" className="form-control newVal"/></div>
                                <div className="div_ayNac"><input type="date" placeholder="Nacimiento" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente1_fecha_nacimiento} name="ayuda_mutua_dependiente1_fecha_nacimiento" className="form-control "/></div>
                                <div className="div_aypar">{(this.state.ayuda_habilitada==""?<MiSelect filas={datos_parentezco2} padre={0} miNombre="ayuda_mutua_dependiente1_parentezco" evtCambios={this.evtChange}  valor={this.state.ayuda_mutua_dependiente1_parentezco} />: null)}</div>
                            </div>
                            
                            <div className="rowAyuda2">
                                <div className="div_ayNom"><input type="text" placeholder="Nombre" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente2_nombre} name="ayuda_mutua_dependiente2_nombre" className="form-control newVal"/></div>
                                <div className="div_ayCed"><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente2_cedula} name="ayuda_mutua_dependiente2_cedula" className="form-control newVal"/></div>
                                <div className="div_ayNac"><input type="date" placeholder="Nacimiento" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente2_fecha_nacimiento} name="ayuda_mutua_dependiente2_fecha_nacimiento" className="form-control "/></div>
                                <div className="div_aypar">{(this.state.ayuda_habilitada==""?<MiSelect filas={datos_parentezco2} padre={0} miNombre="ayuda_mutua_dependiente2_parentezco" evtCambios={this.evtChange}  valor={this.state.ayuda_mutua_dependiente2_parentezco} />: null)}</div>
                            </div>
                            <div className="rowAyuda2">
                                <div className="div_ayNom"><input type="text" placeholder="Nombre" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente3_nombre} name="ayuda_mutua_dependiente3_nombre" className="form-control newVal"/></div>
                                <div className="div_ayCed"><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente3_cedula} name="ayuda_mutua_dependiente3_cedula" className="form-control newVal"/></div>
                                <div className="div_ayNac"><input type="date" placeholder="Nacimiento" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente3_fecha_nacimiento} name="ayuda_mutua_dependiente3_fecha_nacimiento" className="form-control "/></div>
                                <div className="div_aypar">{(this.state.ayuda_habilitada==""?<MiSelect filas={datos_parentezco2} padre={0} miNombre="ayuda_mutua_dependiente3_parentezco" evtCambios={this.evtChange}  valor={this.state.ayuda_mutua_dependiente3_parentezco} />: null)}</div>
                            </div>
                            <div className="rowAyuda2">
                                <div className="div_ayNom"><input type="text" placeholder="Nombre" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente4_nombre} name="ayuda_mutua_dependiente4_nombre" className="form-control newVal"/></div>
                                <div className="div_ayCed"><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente4_cedula} name="ayuda_mutua_dependiente4_cedula" className="form-control newVal"/></div>
                                <div className="div_ayNac"><input type="date" placeholder="Nacimiento" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente4_fecha_nacimiento} name="ayuda_mutua_dependiente4_fecha_nacimiento" className="form-control "/></div>
                                <div className="div_aypar">{(this.state.ayuda_habilitada==""?<MiSelect filas={datos_parentezco2} padre={0} miNombre="ayuda_mutua_dependiente4_parentezco" evtCambios={this.evtChange}  valor={this.state.ayuda_mutua_dependiente4_parentezco} />: null)}</div>
                            </div>
                            <div className="rowAyuda2">
                                <div className="div_ayNom"><input type="text" placeholder="Nombre" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente5_nombre} name="ayuda_mutua_dependiente5_nombre" className="form-control newVal"/></div>
                                <div className="div_ayCed"><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente5_cedula} name="ayuda_mutua_dependiente5_cedula" className="form-control newVal"/></div>
                                <div className="div_ayNac"><input type="date" placeholder="Nacimiento" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente5_fecha_nacimiento} name="ayuda_mutua_dependiente5_fecha_nacimiento" className="form-control "/></div>
                                <div className="div_aypar">{(this.state.ayuda_habilitada==""?<MiSelect filas={datos_parentezco2} padre={0} miNombre="ayuda_mutua_dependiente5_parentezco" evtCambios={this.evtChange}  valor={this.state.ayuda_mutua_dependiente5_parentezco} />: null)}</div>
                            </div>
                            <div className="rowAyuda2">
                                <div className="div_ayNom"><input type="text" placeholder="Nombre" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente6_nombre} name="ayuda_mutua_dependiente6_nombre" className="form-control newVal"/></div>
                                <div className="div_ayCed"><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente6_cedula} name="ayuda_mutua_dependiente6_cedula" className="form-control newVal"/></div>
                                <div className="div_ayNac"><input type="date" placeholder="Nacimiento" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente6_fecha_nacimiento} name="ayuda_mutua_dependiente6_fecha_nacimiento" className="form-control "/></div>
                                <div className="div_aypar">{(this.state.ayuda_habilitada==""?<MiSelect filas={datos_parentezco2} padre={0} miNombre="ayuda_mutua_dependiente6_parentezco" evtCambios={this.evtChange}  valor={this.state.ayuda_mutua_dependiente6_parentezco} />: null)}</div>
                            </div>
                            <div className="rowAyuda2">
                                <div className="div_ayNom"><input type="text" placeholder="Nombre" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente7_nombre} name="ayuda_mutua_dependiente7_nombre" className="form-control newVal"/></div>
                                <div className="div_ayCed"><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente7_cedula} name="ayuda_mutua_dependiente7_cedula" className="form-control newVal"/></div>
                                <div className="div_ayNac"><input type="date" placeholder="Nacimiento" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente7_fecha_nacimiento} name="ayuda_mutua_dependiente7_fecha_nacimiento" className="form-control "/></div>
                                <div className="div_aypar">{(this.state.ayuda_habilitada==""?<MiSelect filas={datos_parentezco2} padre={0} miNombre="ayuda_mutua_dependiente7_parentezco" evtCambios={this.evtChange}  valor={this.state.ayuda_mutua_dependiente7_parentezco} />: null)}</div>
                            </div>
                            <div className="rowAyuda2">
                                <div className="div_ayNom"><input type="text" placeholder="Nombre" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente8_nombre} name="ayuda_mutua_dependiente8_nombre" className="form-control newVal"/></div>
                                <div className="div_ayCed"><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente8_cedula} name="ayuda_mutua_dependiente8_cedula" className="form-control newVal"/></div>
                                <div className="div_ayNac"><input type="date" placeholder="Nacimiento" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente8_fecha_nacimiento} name="ayuda_mutua_dependiente8_fecha_nacimiento" className="form-control "/></div>
                                <div className="div_aypar">{(this.state.ayuda_habilitada==""?<MiSelect filas={datos_parentezco2} padre={0} miNombre="ayuda_mutua_dependiente8_parentezco" evtCambios={this.evtChange}  valor={this.state.ayuda_mutua_dependiente8_parentezco} />: null)}</div>
                            </div>
                            <div className="rowAyuda2">
                                <div className="div_ayNom"><input type="text" placeholder="Nombre" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente9_nombre} name="ayuda_mutua_dependiente9_nombre" className="form-control newVal"/></div>
                                <div className="div_ayCed"><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente9_cedula} name="ayuda_mutua_dependiente9_cedula" className="form-control newVal"/></div>
                                <div className="div_ayNac"><input type="date" placeholder="Nacimiento" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente9_fecha_nacimiento} name="ayuda_mutua_dependiente9_fecha_nacimiento" className="form-control "/></div>
                                <div className="div_aypar">{(this.state.ayuda_habilitada==""?<MiSelect filas={datos_parentezco2} padre={0} miNombre="ayuda_mutua_dependiente9_parentezco" evtCambios={this.evtChange}  valor={this.state.ayuda_mutua_dependiente9_parentezco} />: null)}</div>
                            </div>
                            <div className="rowAyuda2">
                                <div className="div_ayNom"><input type="text" placeholder="Nombre" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente10_nombre} name="ayuda_mutua_dependiente10_nombre" className="form-control newVal"/></div>
                                <div className="div_ayCed"><input type="text" placeholder="Cédula" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente10_cedula} name="ayuda_mutua_dependiente10_cedula" className="form-control newVal"/></div>
                                <div className="div_ayNac"><input type="date" placeholder="Nacimiento" disabled={this.state.ayuda_habilitada} autoComplete="off" onChange={this.evtChange} value={this.state.ayuda_mutua_dependiente10_fecha_nacimiento} name="ayuda_mutua_dependiente10_fecha_nacimiento" className="form-control "/></div>
                                <div className="div_aypar">{(this.state.ayuda_habilitada==""?<MiSelect filas={datos_parentezco2} padre={0} miNombre="ayuda_mutua_dependiente10_parentezco" evtCambios={this.evtChange}  valor={this.state.ayuda_mutua_dependiente10_parentezco} />: null)}</div>
                            </div>
                            
                        </div>
                        <div className="newGrupo">
                            <div className="newTitulo">Control de Lavado de Activos</div>
                            <div style={{textAlign: 'center'}}><h6>Dando cumplimiento a la Ley 155-17 sobre Lavado de Activos y Financiamiento del Terrorismo, por favor llene las siguientes informaciones:</h6></div>
                            <div className="rowLav">
                                <div>Sí</div>
                                <div>No</div>
                                <div></div>
                            </div>
                            <div className='rowLav '>
                                <div><input type='radio' className=' ' checked={this.state.lv_trabajo_publico==1} onChange={this.lv_cambios} id='lv_trabajo_publico_si' value="1" name="lv_trabajo_publico"/></div>
                                <div><input type='radio' className=' ' checked={this.state.lv_trabajo_publico==2} onChange={this.lv_cambios} id='lv_trabajo_publico_no' value="2" name="lv_trabajo_publico"/></div>
                                <div><label className='form-label' for='lv_trabajo_publico'>¿Ha trabajado en el sector público en los últimos 3 años?</label></div>
                            </div>
                            <div className=' rowLav'>
                                <div><input type='radio' className=' ' checked={this.state.lv_manejo_recursos==1} onChange={this.lv_cambios} id='lv_manejo_recursos_si' value="1" name="lv_manejo_recursos"/></div>
                                <div><input type='radio' className=' ' checked={this.state.lv_manejo_recursos==2} onChange={this.lv_cambios} id='lv_manejo_recursos_no' value="2" name="lv_manejo_recursos"/></div>
                                <div><label class='form-label' for='lv_manejo_recursos'>¿Ha manejado o maneja recursos públicos durante los últimos 3 años?</label></div>
                            </div>
                            <div className=' rowLav'>
                                <div><input type='radio' className=' ' checked={this.state.lv_aspira_puesto_publico==1} onChange={this.lv_cambios} id='lv_aspira_puesto_publico_si' value="1" name="lv_aspira_puesto_publico"/></div>
                                <div><input type='radio' className=' ' checked={this.state.lv_aspira_puesto_publico==2} onChange={this.lv_cambios} id='lv_aspira_puesto_publico_no' value="2" name="lv_aspira_puesto_publico"/></div>
                                <div><label class='form-label' for='lv_aspira_puesto_publico'>¿Aspira a algún puesto político actualmente?</label></div>
                            </div>
                            <div className=' rowLav'>
                                <div><input type='radio' className=' ' checked={this.state.lv_familiares==1} onChange={this.lv_cambios} id='lv_familiares_si' value="1" name="lv_familiares"/></div>
                                <div><input type='radio' className=' ' checked={this.state.lv_familiares==2} onChange={this.lv_cambios} id='lv_familiares_no' value="2" name="lv_familiares"/></div>
                                <div><label class='form-label' for='lv_familiares'>¿Alguna de las preguntas anteriores aplica a un familiar?</label></div>
                            </div>
                            <div className="rowLav" style={{gridTemplateColumns: '120px 1fr'}}>
                                <div><label for="lv_familiares_detalle" className="form-label form-label-sm ">Especifique:</label></div>
                                <div><input type="text" autocomplete="off" value={this.state.lv_familiares_detalle} onChange={this.lv_cambios} className="form-control form-control-sm newVal" name="lv_familiares_detalle" /></div>
                            </div>
                            <div className=' rowLav'>
                                <div><input type='radio' className=' ' checked={this.state.lv_otra_actividad==1} onChange={this.lv_cambios} id='lv_otra_actividad_si' value="1" name="lv_otra_actividad"/></div>
                                <div><input type='radio' className=' ' checked={this.state.lv_otra_actividad==2} onChange={this.lv_cambios} id='lv_otra_actividad_no' value="2" name="lv_otra_actividad"/></div>
                                <div><label class='form-label' for='lv_otra_actividad'>¿Realiza alguna otra actividad que le genere ingresos?</label></div>
                            </div>
                            <div className="rowLav" style={{gridTemplateColumns: '120px 1fr', marginBottom: '20px'}}>
                                <div><label for="lv_otra_actividad_detalle" className="form-label form-label-sm ">Especifique:</label></div>
                                <div><input type="text" autocomplete="off" className="form-control form-control-sm newVal" onChange={this.lv_cambios} value={this.state.lv_otra_actividad_detalle} name="lv_otra_actividad_detalle" /></div>
                            </div>
                            <div className="rowLav2">
                                <div><label for="lv_otra_actividad_ingresos_rd" class="form-label form-label-sm lbls">Ingresos Otra Actividad RD$:</label></div>
                                <MiEdit valor={this.state.lv_otra_actividad_ingresos_rd} evtChange={this.lv_cambios} miNombre="lv_otra_actividad_ingresos_rd" />
                            </div>
                            <div className="rowLav2">
                                <div><label for="lv_otra_actividad_ingresos_us" class="form-label form-label-sm lbls">Ingresos Otra Actividad US$:</label></div>
                                <MiEdit valor={this.state.lv_otra_actividad_ingresos_us} evtChange={this.lv_cambios} miNombre="lv_otra_actividad_ingresos_us" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default NuevosSocios;
